
  @media only screen and (min-device-width: 0px) and (max-device-width: 200px) {

    .imgggggg{
 
      color: '#0c1719';
      font-size: 17px;
      font-weight: bold;
      background-color:  #3a7b83  ;
      border-radius: 25px;
      margin-top: 17px;
      margin-left: 20px
 
    }
    
    .img{
 
      height: 2px;
      width: 2px;
      z-index: -1;
      text-align: center;
      margin-left: auto;
    
    
      
     
  }
  .footer-container {
    background-color: #b8b1b156;
    padding: 1rem 10 2rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-right: auto;
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
    opacity: 0.9;

    
  }
  .dropdown-menu a{
     
    font-family:  monospace;


}
.hai{


  text-align: center;
  font-family: David;
  font-weight: bold;

}
.haim{

width: 10%;
height: 10%;

}
      
  
      
        
      }


  @media only screen and (min-device-width: 200px) and (max-device-width: 300px) {

    .imgggggg{
 
      color: '#0c1719';
      font-size: 17px;
      font-weight: bold;
      background-color:  #3a7b83  ;
      border-radius: 25px;
      margin-top: 17px;
      margin-left: 20px
 
    }
    
    .img{
 
      height: 2px;
      width: 2px;
      z-index: -1;
      text-align: center;
      margin-left: auto;
    
    
      
     
  }
  .footer-container {
    background-color: #b8b1b156;
    padding: 1rem 10 2rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-right: auto;
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
    opacity: 0.9;

    
  }
  .dropdown-menu a{
     
    font-family:  monospace;


}
.hai{


  text-align: center;
  font-family: David;
  font-weight: bold;

}
.haim{

width: 10%;
height: 10%;

}
      
  
      
        
      }
  @media only screen and (min-device-width: 300px) and (max-device-width: 400px) {

    .imgggggg{
     
      color: '#0c1719';
      font-size: 17px;
      font-weight: bold;
      background-color:  #3a7b83  ;
      border-radius: 25px;
      margin-top: 17px;
      margin-left: 20px
 
    }
    
    .img{
 
      
      height:2px;
      width:2px;
      z-index: -1;
      text-align: center;
      margin-left: auto;
    
    
      
     
  }
      
  .footer-container {
    background-color: #b8b1b156;
    padding: 1rem 10 2rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-right: auto;
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
    opacity: 0.9;

    
  }
  .dropdown-menu a{
     
    font-family:  monospace;


}
.hai{


  text-align: center;
  font-family: David;
  font-weight: bold;

}
.haim{

width: 10%;
height: 10%;

}
        
      }
    
      @media only screen and (min-device-width: 400px) and (max-device-width: 550px){
  
        
        .imgggggg{
 
          color: '#0c1719';
          font-size: 17px;
          font-weight: bold;
          background-color:  #3a7b83  ;
          border-radius: 25px;
          margin-top: 17px;
          margin-left: 20px
     
        }
        
        .img{
     
          height: 2px;
          width: 2px;
          z-index: -1;
          text-align: center;
          margin-left: auto;
        
        
          
         
      }
      .footer-container {
       
        background-color: #b8b1b156;
        padding: 1rem 10 2rem 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin-right: auto;
        margin-left: auto;
        margin-top: auto;
        margin-bottom: auto;
        opacity: 0.9;

      }
      .dropdown-menu a{
     
        font-family:  monospace;


    }
    .hai{


      text-align: center;
      font-family: David;
      font-weight: bold;

    }
    .haim{

width: 10%;
height: 10%;

    }
  
      }
    
  
  
      @media only screen and (min-device-width: 550px) and (max-device-width: 800px){
  
        .imgggggg{
 
          color: '#0c1719';
          font-size: 17px;
          font-weight: bold;
          background-color:  #3a7b83  ;
          border-radius: 25px;
          margin-top: 17px;
          margin-left: 20px
     
        }
        
        .img{
     
          height: 2px;
          width: 2px;
          z-index: -1;
          text-align: center;
          margin-left: auto;
        
        
          
         
      }       
      .footer-container {
        background-color: #b8b1b156;
        padding: 1rem 10 2rem 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin-right: auto;
        margin-left: auto;
        margin-top: auto;
        margin-bottom: auto;
        opacity: 0.9;

        
      }
      .dropdown-menu a{
     
        font-family:  monospace;


    }
    .hai{


      text-align: center;
      font-family: David;
      font-weight: bold;

    }
    .haim{

width: 15%;
height: 15%;

    }
          
        
  
      }
      @media only screen and (min-device-width: 800px) and (max-device-width: 1000px){
      
        .imgggggg{
 
          color: '#0c1719';
          font-size: 17px;
          font-weight: bold;
          background-color:  #3a7b83  ;
          border-radius: 25px;
          margin-top: 17px;
          margin-left: 20px
     
        }
        
        .img{
     
          height: 2px;
          width: 2px;
          z-index: -1;
          text-align: center;
          margin-left: auto;
        
        
          
         
      }
      .footer-container {
        background-color: #b8b1b156;
        padding: 1rem 10 2rem 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin-right: auto;
        margin-left: auto;
        margin-top: auto;
        margin-bottom: auto;
        opacity: 0.9;

        
      }
      .dropdown-menu a{
     
        font-family:  monospace;


    }
    .hai{


      text-align: center;
      font-family: David;
      font-weight: bold;

    }
    .haim{

width: 15%;
height: 15%;

    }
  
      }
  
  
      @media only screen and (min-device-width: 1000px) and (max-device-width: 1200px){
      
        .imgggggg{
 
          color: '#0c1719';
          font-size: 17px;
          font-weight: bold;
          background-color:  #3a7b83  ;
          border-radius: 25px;
          margin-top: 17px;
          margin-left: 20px
     
        }
        
        .img{
     
          height: 2px;
          width: 2px;
          z-index: -1;
          text-align: center;
          margin-left: auto;
        
        
          
         
      }
      .footer-container {
        background-color: #ebe3e356;
        padding: 1rem 10 2rem 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin-right: auto;
        margin-left: auto;
        margin-top: auto;
        margin-bottom: auto;
        opacity: 0.9;

   
        
      }
      .dropdown-menu a{
     
        font-family:  monospace;


    }
    .hai{


      text-align: center;
      font-family: David;
      font-weight: bold;

    }
    .haim{

width: 15%;
height: 15%;

    }
  
      }
  
  
  
      
      @media only screen and (min-width: 1200px) {
        

        .imgggggg{
 
          color: '#0c1719';
          font-size: 17px;
          font-weight: bold;
          background-color:  #3a7b83  ;
          border-radius: 25px;
          margin-top: 17px;
          margin-left: 20px
     
        }
   
        .img{
     
        
          width: 20px;
          z-index: -1;
          background-position: center;
          text-align: center;
          margin-right: auto;
          margin-left: auto;
          margin-top: auto;
          margin-bottom: auto;

        
          
         
      }

      .footer-container {
        background-color: #ebe3e356;
        padding: 1rem 10 2rem 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin-right: auto;
        margin-left: auto;
        margin-top: auto;
        margin-bottom: auto;
        opacity: 0.9;
  

   
        
      }

      .custom-dropdown {
        -webkit-appearance: none;
        margin-left: 190px;
        -moz-appearance: none;
        border: 0 !important;
        -webkit-border-radius: 5px;
        border-radius: 5px;
        font-size: 14px;
        padding: 10px;
        width: 35%;
        cursor: pointer;
        background-size: 40px 37px;
        color: #000;
      
  
      }



    .hai{


      text-align: center;
      font-family: David;

    }
    .haim{

width: 15%;
height: 15%;

    }
      
      }
      
      
      
      
      
      
      
  
    
  
    

      .footer-container {
        background-color: #ebe3e356;
        padding: 1rem 10 2rem 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin-right: auto;
        margin-left: auto;
        margin-top: auto;
        margin-bottom: auto;
        opacity: 0.9;
  

   
        
      }








