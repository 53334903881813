


  @media only screen and (min-device-width: 300px) and (max-device-width: 400px) {



 
        
        
        .bb{
        
            font-family: David;
            text-align: center;
            font-size: 8px;
            font-variant: normal;
          
        
        
        }

    
      
    

    
      
}

@media only screen and (min-device-width: 400px) and (max-device-width: 550px){

     
    .bb{
        
        font-family: David;
        text-align: center;
        font-size: 8px;
        font-variant: normal;
     
    
    
    }

    
  

}



@media only screen and (min-device-width: 550px) and (max-device-width: 800px){


       
    .bb{
        
        font-family: David;
        text-align: center;
        font-size: 16px;
        font-variant: normal;
  
    
    
    }

  
    
  

}
@media only screen and (min-device-width: 800px) and (max-device-width: 1200px){

     
    .bb{
        
        font-family: David;
        text-align: center;
        font-size: 17px;
        font-variant: normal;
     
    
    
    }



}






@media only screen and (min-width: 1200px) {


    .bb{

        font-family: David;
        text-align: center;
        font-size: 20px;
      
       
    }
    

    .body{

        width:100%;
        height: 65vh;
          background-image: url('./assets/cov.jpg')  ;
          background-repeat: no-repeat;
          background-attachment: fixed;
          background-position: center;
          background-size: cover;
          display: flex;
          flex-direction: row;
          justify-content: space-evenly;
          align-items: flex-end;
          padding: 10px;/*OR padding-bottom: 10px;*/
          opacity: 1.0;
       
       }



}




