



  @media only screen and (min-device-width: 200px) and (max-device-width: 300px) {



    body {
        background-color:#FFFFFF;
        text-align: right;
        font-size: 8px;


  
      }

      .textaliht{



        font-family: David;
        text-align: center;
        font-size: 10px;
        
        
        
        
        }
        
        .t{


       
            text-align: right;
            font-size: 14px;
            text-justify:auto;
           
            
        }
        .im{

           height: 30px;
              width: 30px;


        }

        .aa{
            background-position: center;
            text-align: center;
            min-width:40px ;
            text-align: right;
            font-size: 9px;
            text-justify:auto;
           
            
        }
    
      
    

    
      
}


  @media only screen and (min-device-width: 300px) and (max-device-width: 400px) {



    body {
        background-color:#FFFFFF;
        text-align: right;
        font-size: 8px;


  
      }

      .textaliht{



        font-family: David;
        text-align: center;
        font-size: 10px;
        
        
        
        
        }
        
        .t{


       
            text-align: right;
            font-size: 14px;
            text-justify:auto;
           
            
        }
        .im{

           height: 30px;
              width: 30px;


        }

        .aa{
            background-position: center;
            text-align: center;
            min-width:40px ;
            text-align: right;
            font-size: 13px;
            text-justify:auto;
           
            
        }
    
      
    

    
      
}

@media only screen and (min-device-width: 400px) and (max-device-width: 550px){


    body {
        background-color:#FFFFFF;
        text-align: right;
        font-size: 8px;


  
      }

      .textaliht{



        font-family: David;
        text-align: center;
        font-size: 15px;
        
        
        
        
        }
        
        .t{


            font-family: David;
            text-align: right;
            font-size: 14px;
            text-justify:auto;
           
            
        }
        .im{
           
           height: 30px;
              width: 30px;


        }
        .aa{
            background-position: center;
            text-align: center;
            min-width:40px ;
            text-align: right;
            font-size: 17px;
            text-justify:auto;
           
            
        }

  

}



@media only screen and (min-device-width: 550px) and (max-device-width: 800px){

    body {
        background-color:#FFFFFF;
        text-align: right;
        font-size: 8px;


  
      }

      .textaliht{



        font-family: David;
        text-align: right;
        font-size: 10px;
        
        
        
        
        }
        
        .t{


            font-family: David;
            text-align: right;
            font-size: 14px;
            text-justify:auto;
           
            
        }
        .im{
           
           height: 40px;
              width: 40px;


        }
        .aa{
            background-position: center;
            text-align: center;
            min-width:40px ;
            text-align: right;
            font-size: 24px;
            text-justify:auto;
           
            
        }
  
  
    
  

}
@media only screen and (min-device-width: 800px) and (max-device-width: 1200px){



      .textaliht{



        font-family: David;
        text-align: center;
        font-size: 10px;
        
        
        
        
        }
        
        .t{


            font-family: David;
            text-align: right;
            font-size: 14px;
            text-justify:auto;
           
            
        }
        .im{
           
           height: 40px;
              width: 40px;


        }
        .aa{
            background-position: center;
            text-align: center;
            min-width:40px ;
            text-align: right;
            font-size: 30px;
            text-justify:auto;
           
            
        }
  

}






@media only screen and (min-width: 1200px) {



    .textaliht{


        min-width:40px ;
        text-align: center;
         font-size: 30px;
    
    
    
    
    }
    
    .icon{
    
       
        text-align: center;
        font-size: 15px;
        height: 40px;
        width: 1060px;
        margin-left: 40px;
     
    }
    
    
    
    .img{
     
        text-align: center;
    
        z-index: -1;
       
       
       
    }
    .aa{
        background-position: center;
        text-align: center;
        min-width:40px ;
        text-align: right;
        font-size: 34px;
        text-justify:auto;
       
        
    }
    
    
    .im{
        text-align: center;
        height: 65px;
           width: 65px;


     }



}








