




  @media only screen and (min-device-width: 300px) and (max-device-width: 400px) {

   .b{


      text-align: center;
   
      
   }
   
   .immm{
   
      height: 60px;
      width: 75px;
   
   }
   
   
   .ta {
      display: table;
       border-collapse: separate;
       margin: auto;
      margin-left: auto;
      margin-right: auto;
      margin-top: auto;
      margin-bottom: auto;
     }
   

    

    
      
    

    
      
}

@media only screen and (min-device-width: 400px) and (max-device-width: 550px){

   .b{


      text-align: center;
   
      
   }
   
   .immm{
   
      height: 70px;
      width: 85px;
   
   }
   
   
   .ta {
      display: table;
       border-collapse: separate;
       margin: auto;
      margin-left: auto;
      margin-right: auto;
      margin-top: auto;
      margin-bottom: auto;
     }
   
    
  

}



@media only screen and (min-device-width: 550px) and (max-device-width: 800px){


  
   .b{


      text-align: center;
   
      
   }
   
   .immm{
   
      height: 100px;
      width: 125px;
   
   }
   
   
   .ta {
      display: table;
       border-collapse: separate;
       margin: auto;
      margin-left: auto;
      margin-right: auto;
      margin-top: auto;
      margin-bottom: auto;
     }
   
    
  

}
@media only screen and (min-device-width: 800px) and (max-device-width: 1200px){

   .b{


      text-align: center;
   
      
   }
   
   .immm{
   
      height: 120px;
      width: 145px;
   
   }
   
   
   .ta {
      display: table;
       border-collapse: separate;
       margin: auto;
      margin-left: auto;
      margin-right: auto;
      margin-top: auto;
      margin-bottom: auto;
     }
   

}






@media only screen and (min-width: 1200px) {


   .ta {
      display: table;
     
      margin: auto;
     margin-left: auto;
    }
  

  .b{




   text-align: center
   }

   .immm{
    
      transition-duration: 1s;
      height: 140px;
      width: 235px;
      transform: scale(1.0);
      transition-property: transform;
   
   
   }

    



}












