      /*
    Auther: Abdelrhman Said
*/


* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  *:focus,
  *:active {
    outline: none !important;
    -webkit-tap-highlight-color: transparent;
  }
  
  
  
  .wrapper {
    display: inline-flex;
    
  }
  
  .wrapper .icon {
    position: relative;
    background-color: #ffffff;
    border-radius: 50%;
    padding: 15px;
    margin: 10px;
    width: 50px;
    height: 50px;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }
  
  .wrapper .tooltip {
    position: absolute;
    top: 0;
    font-size: 14px;
    background-color: #ffffff;
    color: #ffffff;
    padding: 5px 8px;
    border-radius: 5px;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
    opacity: 0;
    pointer-events: none;
    transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }
  
  .wrapper .tooltip::before {
    position: absolute;
    content: "";
    height: 8px;
    width: 8px;
    background-color: #ffffff;
    bottom: -3px;
    left: 50%;
    transform: translate(-50%) rotate(45deg);
    transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }
  
  .wrapper .icon:hover .tooltip {
    top: -45px;
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
  }
  
  .wrapper .icon:hover span,
  .wrapper .icon:hover .tooltip {
    text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.1);
  }
  
  .wrapper .facebook:hover,
  .wrapper .facebook:hover .tooltip,
  .wrapper .facebook:hover .tooltip::before {
    background-color: #3b5999;
    color: #ffffff;
  }
  
  .wrapper .twitter:hover,
  .wrapper .twitter:hover .tooltip,
  .wrapper .twitter:hover .tooltip::before {
    background-color: #5cc732;
    color: #ffffff;
  }
  
  .wrapper .instagram:hover,
  .wrapper .instagram:hover .tooltip,
  .wrapper .instagram:hover .tooltip::before {
    background-color: #e1306c;
    color: #ffffff;
  }
  
  .wrapper .github:hover,
  .wrapper .github:hover .tooltip,
  .wrapper .github:hover .tooltip::before {
    background-color: #333333;
    color: #ffffff;
  }
  
  .wrapper .youtube:hover,
  .wrapper .youtube:hover .tooltip,
  .wrapper .youtube:hover .tooltip::before {
    background-color: #de463b;
    color: #ffffff;
  }
  
@media (max-device-width: 350px){

    .wrapper .icon {
      position: relative;
      background-color: #ffffff;
      border-radius: 50%;
      padding: 15px;
      margin: 10px;
      width: 40px;
      height: 40px;
      font-size: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
      cursor: pointer;
      transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    }
  }

  @media (max-device-width: 300px){

    .wrapper .icon {
      position: relative;
      background-color: #ffffff;
      border-radius: 50%;
      padding: 15px;
      margin: 10px;
      width: 10px;
      height: 10px;
      font-size: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
      cursor: pointer;
      transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    }

}








  @media only screen and (min-device-width: 300px) and (max-device-width: 400px) {




    .icon{


        text-align: center;
        font-size: 15px;
        height: 40px;
        width: 1060px;
        margin-left: 8px;
    
    
    }

    .bot{
         

     text-align: center;
     padding-left: 50px;

    }

    .mobile{

        text-align: center;
    
    
    }

    .btnbtn{
        text-align: center;
      margin-right: 140px;
      border-radius: 15px;
    
    }

    .scroll {
        opacity: 0.3;
        background-color: aqua;
        width: 40px;
        height: 40px;
        position: fixed;
        bottom: 10px;
        right: 10px;
        border-radius: 5px;
        border: none;
        
    }



    .btnScrollToTop{




        position: fixed;
        right: 10px;
        bottom: 10px;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background: #ee6622;
        box-shadow: 00 10px red;
        color: #FFFFFF;
        outline: none;
        cursor:pointer;
        
        
        
        }
        
        .bt{
            text-align: center;
            margin-right: 95px;
            border-radius: 15px;
        
        
        }
        
        .teee{

            color: '#bbb';
            font-size: 10px;
            font-weight: bold;
         
            border-radius: 124px;
            text-align: center;
            width: 220px;
            height: 45px;
            margin-right: 13%;
            
          }
 
    

    
      
    

    
      
    }
  
    @media only screen and (min-device-width: 400px) and (max-device-width: 550px){

    
        .icon{


            text-align: center;
            font-size: 15px;
            height: 40px;
            width: 1060px;
            margin-left: 8px;
        
        
        }
    
        .bot{
             
    
         text-align: center;
         padding-left: 50px;
    
        }
    
        .mobile{
    
            text-align: center;
        
        
        }
    
        .btnbtn{
            text-align: center;
          margin-right: 140px;
          border-radius: 15px;
        
        }
    
        .scroll {
            opacity: 0.3;
            background-color: aqua;
            width: 40px;
            height: 40px;
            position: fixed;
            bottom: 10px;
            right: 10px;
            border-radius: 5px;
            border: none;
            
        }
    
    
    
        .btnScrollToTop{
    
    
    
    
            position: fixed;
            right: 10px;
            bottom: 10px;
            width: 50px;
            height: 50px;
            border-radius: 50%;
            background: #ee6622;
            box-shadow: 00 10px red;
            color: #FFFFFF;
            outline: none;
            cursor:pointer;
            
            
            
            }
            
            .bt{
                text-align: center;
                margin-right: 95px;
                border-radius: 15px;
            
            
            }
            
            .teee{
    
                color: '#bbb';
                font-size: 13px;
                font-weight: bold;
              
                border-radius: 124px;
                text-align: center;
                width: 300px;
                height: 45px;
                margin-right: 7%;
                
              }
     
        
      

    }
  


    @media only screen and (min-device-width: 550px) and (max-device-width: 800px){

   
      
        .icon{


            text-align: center;
            font-size: 15px;
            height: 40px;
            width: 1060px;
            margin-left: 8px;
        
        
        }
    
        .bot{
             
    
         text-align: center;
         padding-left: 50px;
    
        }
    
        .mobile{
    
            text-align: center;
        
        
        }
    
        .btnbtn{
            text-align: center;
          margin-right: 140px;
          border-radius: 15px;
        
        }
    
        .scroll {
            opacity: 0.3;
            background-color: aqua;
            width: 40px;
            height: 40px;
            position: fixed;
            bottom: 10px;
            right: 10px;
            border-radius: 5px;
            border: none;
            
        }
    
    
    
        .btnScrollToTop{
    
    
    
    
            position: fixed;
            right: 10px;
            bottom: 10px;
            width: 50px;
            height: 50px;
            border-radius: 50%;
            background: #ee6622;
            box-shadow: 00 10px red;
            color: #FFFFFF;
            outline: none;
            cursor:pointer;
            
            
            
            }
            
            .bt{
                text-align: center;
                margin-right: 95px;
                border-radius: 15px;
            
            
            }
            
            .teee{
    
                color: '#bbb';
                font-size: 13px;
                font-weight: bold;
         
                border-radius: 124px;
                text-align: center;
                width: 500px;
                height: 45px;
                margin-right: 8%;
                
              }
     
      
        
      

    }
    @media only screen and (min-device-width: 800px) and (max-device-width: 1200px){

      
        .icon{


            text-align: center;
            font-size: 15px;
            height: 40px;
            width: 1060px;
            margin-left: 8px;
        
        
        }
    
        .bot{
             
    
         text-align: center;
         padding-left: 50px;
    
        }
    
        .mobile{
    
            text-align: center;
        
        
        }
    
        .btnbtn{
            text-align: center;
          margin-right: 140px;
          border-radius: 15px;
        
        }
    
        .scroll {
            opacity: 0.3;
            background-color: aqua;
            width: 40px;
            height: 40px;
            position: fixed;
            bottom: 10px;
            right: 10px;
            border-radius: 5px;
            border: none;
            
        }
    
    
    
        .btnScrollToTop{
    
    
    
    
            position: fixed;
            right: 10px;
            bottom: 10px;
            width: 50px;
            height: 50px;
            border-radius: 50%;
            background: #ee6622;
            box-shadow: 00 10px red;
            color: #FFFFFF;
            outline: none;
            cursor:pointer;
            
            
            
            }
            
            .bt{
                text-align: center;
                margin-right: 95px;
                border-radius: 15px;
            
            
            }
            
            .teee{
    
                color: '#bbb';
                font-size: 16px;
                font-weight: bold;
     
                border-radius: 124px;
                text-align: center;
                width: 600px;
                height: 49px;
                margin-right: 10%;
                margin-left: 2%;
                
              }

    }


 


    
    @media only screen and (min-width: 1200px) {
    
    
    
          
        .icon{


            text-align: center;
            font-size: 15px;
            height: 40px;
            width: 1060px;
            margin-left: 8px;
        
        
        }
    
        .bot{
             
    
         text-align: center;
         padding-left: 50px;
    
        }
    
        .mobile{
    
            text-align: center;
        
        
        }
    
        .btnbtn{
            text-align: center;
          margin-right: 140px;
          border-radius: 15px;
        
        }
    
        .scroll {
            opacity: 0.3;
            background-color: aqua;
            width: 40px;
            height: 40px;
            position: fixed;
            bottom: 10px;
            right: 10px;
            border-radius: 5px;
            border: none;
            
        }
    
    
    
        .btnScrollToTop{
    
    
    
    
            position: fixed;
            right: 10px;
            bottom: 10px;
            width: 50px;
            height: 50px;
            border-radius: 50%;
            background: #ee6622;
            box-shadow: 00 10px red;
            color: #FFFFFF;
            outline: none;
            cursor:pointer;
            
            
            
            }
            
            .bt{
                text-align: center;
                margin-right: 95px;
                border-radius: 15px;
            
            
            }
            
            .teee{
    
                color: '#bbb';
                font-size: 23px;
                font-weight: bold;
                border-radius: 124px;
                text-align: center;
                width: 800px;
                height: 55px;
                margin-top: auto;
                margin-bottom: auto;
                margin-right: auto;
                margin-left: 13%;
              
              }
              .block_center {
                width: 300px;
                margin-right: auto;
                margin-left: auto;
              }
        
    

    
    }
    
    
    












