


  @media only screen and (min-device-width: 300px) and (max-device-width: 400px) {

    .textttttt{
      font-family: David;
        text-align: center;
        font-size: 12px;
     }
    
    
     .abc{
    
    
       text-align: center;
       font-size: 15px;
       height: 40px;
       width: 10px;
       margin-left: 40px;
       margin-top: 350px;
       margin-block-end: auto;
    
    
    }
    

    
.google-maps {  
  width: 80%; 
  height: 40%; 
  margin:  auto;
  position: absolute;
  padding-left: 30px;
  z-index:1;
}

    
      
    

    
      
  }

  @media only screen and (min-device-width: 400px) and (max-device-width: 550px){

  
    .textttttt{
      font-family: David;
        text-align: center;
        font-size: 14px;
     }
    
    
     .abc{
    
    
       text-align: center;
       font-size: 15px;
       height: 40px;
       width: 10px;
       margin-left: 40px;
       margin-top: 370px;
       margin-block-end: auto;
    
    
    }

        
.google-maps {  
  width: 85%; 
  height: 42%; 
  margin:  auto;
  position: absolute;
  padding-left: 25px;
  z-index:1;
}

      
    

  }



  @media only screen and (min-device-width: 550px) and (max-device-width: 800px){

 
    .textttttt{
      font-family: David;
        text-align: center;
        font-size: 18px;
     }
    
    
     .abc{
    
    
      text-align: center;
      font-size: 15px;
      height: 40px;
      width: 10px;
      margin-left: 40px;
      margin-top: 500px;
      margin-block-end: auto;
   
   
   }

       
.google-maps {  
 width: 85%; 
 height: 50%; 
 margin:  auto;
 position: absolute;
 padding-left: 45px;
 z-index:1;
}

    
    
      
    

  }
  @media only screen and (min-device-width: 800px) and (max-device-width: 1200px){


    .textttttt{
      font-family: David;
        text-align: center;
        font-size: 24px;
     }
    
    
     .abc{
    
    
      text-align: center;
      font-size: 15px;
      height: 40px;
      width: 10px;
      margin-left: 40px;
      margin-top: 700px;
      margin-block-end: auto;
   
   
   }

       
.google-maps {  
 width: 65%; 
 height: 70%; 
 margin:  auto;
 position: absolute;
 padding-left: 170px;
 z-index:1;
}


  }





  
  @media only screen and (min-width: 1200px) {
  
  
  
    .textttttt{
      text-align: center;
      font-family: David;
        font-size: 20px;
   
        
     }
    

  
 .m{


  text-align: center;
  margin-left: auto;
  margin-block-end: auto;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  display: flex;
  
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-end;

 }

 .abc{

  text-align: center;
  font-size: 15px;
  height: 40px;
  width: 10px;
  margin-top: 500px;
  margin-block-end: auto;



}

.google-maps {  
  text-align: center;
  width: 50%; 
  height: 50%; 
  margin:  auto;
  position: absolute;
  padding-left: 480px;
  z-index:1;
}

      
  

  
  }
  
  
  
