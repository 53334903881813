
  @media only screen and (min-device-width: 200px) and (max-device-width: 300px) {


    
    .imgg{
  
      height: 3px;
      width: auto;
      z-index: -1;
      text-align: right;
      background-color:  #0693e3  ;
    
    }
      
      
    .righttt{
  
      text-align: right;
     
   
  
    }
  
    .left{
  
  
      margin-left: 500px;
      text-decoration-color: aquamarine;
      padding-left: 100px;
        }
    
  
        .aaa{
  
          width: 35px;
          height: 35px;
    
        }  

        .accessibility{



          text-align: left;
          margin-left: auto;


        }
      
        .ml-auto{
          font-size: 2rem;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: -30px 0px 18px 16px;
          margin: 0px 0px 0px 0px;
        
        }
      
        .box1{
    
          order: 5;
          
          
           }
          
           .box2{
            
            order: 3;
          
          
            
          }
          .box3{
           
            order: 4;
          
            
          }
          .box4{
          
            
            order: 2;
            
          }
          .box5{
          
          
            order: 1;
            
          }
      
        
      }
    


  @media only screen and (min-device-width: 300px) and (max-device-width: 400px) {


    
    .imgg{
  
      height: 3px;
      width: auto;
      z-index: -1;
      text-align: right;
      background-color:  #0693e3  ;
    
    }
      
      
    .righttt{
  
      text-align: right;
     
   
  
    }
    .left{
  
  
      margin-left: 500px;
      text-decoration-color: aquamarine;
      padding-left: 100px;
        }
    
   
  
        .aaa{
  
          width: 35px;
          height: 35px;
    
        }  
      
        .ml-auto{
          font-size: 2rem;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: -30px 0px 18px 16px;
      
        
        }
        .box1{
    
          order: 5;
          
          
           }
          
           .box2{
            
            order: 3;
          
          
            
          }
          .box3{
           
            order: 4;
          
            
          }
          .box4{
          
            
            order: 2;
            
          }
          .box5{
          
          
            order: 1;
            
          }
      
      
        
      }
    
      @media only screen and (min-device-width: 400px) and (max-device-width: 550px){
  
        
          
    .imgg{
  
      height: 3px;
      width: auto;
      z-index: -1;
      text-align: right;
      background-color:  #0693e3  ;
    
    }
      
    .righttt{
  
      text-align: right;
     
   
  
    }
  
    .left{
  
  
      margin-left: 500px;
      text-decoration-color: aquamarine;
      padding-left: 100px;
        }
    
  
        .aaa{
  
          width: 40px;
          height: 40px;
    
        }  
      
        .ml-auto{
          font-size: 2rem;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: -30px 0px 18px 16px;
          margin: 0px 0px 0px 0px;
        
        }
      
        .box1{
    
          order: 5;
          
          
           }
          
           .box2{
            
            order: 3;
          
          
            
          }
          .box3{
           
            order: 4;
          
            
          }
          .box4{
          
            
            order: 2;
            
          }
          .box5{
          
          
            order: 1;
            
          }
      
      }
    
  
  
      @media only screen and (min-device-width: 550px) and (max-device-width: 800px){
  
        .imgg{
  
          height: 2px;
          width: auto;
          z-index: -1;
          text-align: right;
          background-color:  #0693e3  ;
        
        }
          
        .righttt{
      
          text-align: right;
         
       
      
        }
      
        .left{
      
      
          margin-left: 500px;
          text-decoration-color: aquamarine;
          padding-left: 100px;
            }
        
      
            .aaa{
      
              width: 35px;
              height: 35px;
        
            }  
            .menu-bars {
              margin-left: 2rem;
              font-size: 2rem;
              background: none;
            }
            .nav-menu {
              background-color: #060b26;
              width: 250px;
              height: 100vh;
              display: flex;
              justify-content: center;
              position: fixed;
              top: 0;
              left: -100%;
              transition: 850ms;
            }
            .ml-auto{
              font-size: 2rem;
              display: flex;
              justify-content: center;
              align-items: center;
              padding: -30px 0px 18px 16px;
              margin: 0px 0px 0px 0px;
            
            }
            .box1{
    
              order: 5;
              
              
               }
              
               .box2{
                
                order: 3;
              
              
                
              }
              .box3{
               
                order: 4;
              
                
              }
              .box4{
              
                
                order: 2;
                
              }
              .box5{
              
              
                order: 1;
                
              }
          
            
      
          }
    
        
    
      @media only screen and (min-device-width: 800px) and (max-device-width: 1000px){
  
     
        
        .imgg{
      
          height: 2px;
          width: auto;
          z-index: -1;
          text-align: right;
          background-color:  #0693e3  ;
        
        }
          
        .righttt{
      
          text-align: right;
         
       
      
        }
      
        .left{
      
      
          margin-left: 500px;
          text-decoration-color: aquamarine;
          padding-left: 100px;
            }
        
      
            .aaa{
      
              width: 35px;
              height: 35px;
        
            }  
            .menu-bars {
              margin-left: 2rem;
              font-size: 2rem;
              background: none;
            }
            .nav-menu {
              background-color: #060b26;
              width: 250px;
              height: 100vh;
              display: flex;
              justify-content: center;
              position: fixed;
              top: 0;
              left: -100%;
              transition: 850ms;
            }
            .ml-auto{
         
              display: flex;
              justify-content: center;
              align-items: center;
              padding: -1px 0px 18px 16px;
              margin: 0px 0px 0px 0px;
       
            
            }
            .box1{
    
              order: 5;
              
              
               }
              
               .box2{
                
                order: 3;
              
              
                
              }
              .box3{
               
                order: 4;
              
                
              }
              .box4{
              
                
                order: 2;
                
              }
              .box5{
              
              
                order: 1;
                
              }
          
          
            
      
          }
      @media only screen and (min-device-width: 1000px) and (max-device-width: 1200px){
      
        
    .imgg{
  
      height: 3px;
      width: auto;
      z-index: -1;
      text-align: right;
      background-color:  #0693e3  ;
    
    }
  
    .righttt{
  
  
      text-align: right;
     margin-right: -75px;
  
    }
  
    .left{
  
  
      margin-left: 200px;
      text-decoration-color: aquamarine;
      background-color: black;
  
  
      font-size: 100px;
        }
  
     .aaa{
  
       width: 40px;
       height: 40px;
  
     }
     .container-1{
      display: flex;
   
    
     }
    
     .box1{
    
      order: 5;
      
      
       }
      
       .box2{
        
        order: 3;
      
      
        
      }
      .box3{
       
        order: 4;
      
        
      }
      .box4{
      
        
        order: 2;
        
      }
      .box5{
      
      
        order: 1;
        
      }
  
  
      }
  
  
   
  
  
      
      @media only screen and (min-width: 1200px) {
      
      
  
        .imgg{
  
          height: 3px;
          width: auto;
          z-index: -1;
          text-align: right;
          background-color:  #0693e3  ;
        
        }
      
       
      
        .righttt{
      
          text-align: right;
       
      
        }

        .nav-menu {
          background-color: #060b26;
          width: 250px;
          height: 100vh;
          display: flex;
          justify-content: center;
     
          top: 0;
          left: -100%;
          transition: 850ms;
  

          
        }

  
        .left{
      
      
          margin-left: 500px;
          text-decoration-color: aquamarine;
          padding-left: 100px;
            }
        
      
            .aaa{
      
              width: 50px;
              height: 50px;
        
            }

 .container-1{
  display: flex;

 }

 .box1{

  order: 5;
  
  
   }
  
   .box2{
    
    order: 3;
  
  
    
  }
  .box3{
   
    order: 4;
  
    
  }
  .box4{
  
    
    order: 2;
    
  }
  .box5{
  
  
    order: 1;
    
  }
  
      
      }
      
    
    
    
    
    
      .imgg{
  
        height: 3px;
        width: auto;
        z-index: -1;
        text-align: right;
        background-color:  #0693e3  ;
      
      }

  

  