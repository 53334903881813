body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}





  @media only screen and (min-device-width: 200px) and (max-device-width: 300px) {



    body {
        background-color:#FFFFFF;
        text-align: right;
        font-size: 8px;


  
      }

      .textaliht{



        font-family: David;
        text-align: center;
        font-size: 10px;
        
        
        
        
        }
        
        .t{


       
            text-align: right;
            font-size: 14px;
            text-justify:auto;
           
            
        }
        .im{

           height: 30px;
              width: 30px;


        }

        .aa{
            background-position: center;
            text-align: center;
            min-width:40px ;
            text-align: right;
            font-size: 9px;
            text-justify:auto;
           
            
        }
    
      
    

    
      
}


  @media only screen and (min-device-width: 300px) and (max-device-width: 400px) {



    body {
        background-color:#FFFFFF;
        text-align: right;
        font-size: 8px;


  
      }

      .textaliht{



        font-family: David;
        text-align: center;
        font-size: 10px;
        
        
        
        
        }
        
        .t{


       
            text-align: right;
            font-size: 14px;
            text-justify:auto;
           
            
        }
        .im{

           height: 30px;
              width: 30px;


        }

        .aa{
            background-position: center;
            text-align: center;
            min-width:40px ;
            text-align: right;
            font-size: 13px;
            text-justify:auto;
           
            
        }
    
      
    

    
      
}

@media only screen and (min-device-width: 400px) and (max-device-width: 550px){


    body {
        background-color:#FFFFFF;
        text-align: right;
        font-size: 8px;


  
      }

      .textaliht{



        font-family: David;
        text-align: center;
        font-size: 15px;
        
        
        
        
        }
        
        .t{


            font-family: David;
            text-align: right;
            font-size: 14px;
            text-justify:auto;
           
            
        }
        .im{
           
           height: 30px;
              width: 30px;


        }
        .aa{
            background-position: center;
            text-align: center;
            min-width:40px ;
            text-align: right;
            font-size: 17px;
            text-justify:auto;
           
            
        }

  

}



@media only screen and (min-device-width: 550px) and (max-device-width: 800px){

    body {
        background-color:#FFFFFF;
        text-align: right;
        font-size: 8px;


  
      }

      .textaliht{



        font-family: David;
        text-align: right;
        font-size: 10px;
        
        
        
        
        }
        
        .t{


            font-family: David;
            text-align: right;
            font-size: 14px;
            text-justify:auto;
           
            
        }
        .im{
           
           height: 40px;
              width: 40px;


        }
        .aa{
            background-position: center;
            text-align: center;
            min-width:40px ;
            text-align: right;
            font-size: 24px;
            text-justify:auto;
           
            
        }
  
  
    
  

}
@media only screen and (min-device-width: 800px) and (max-device-width: 1200px){



      .textaliht{



        font-family: David;
        text-align: center;
        font-size: 10px;
        
        
        
        
        }
        
        .t{


            font-family: David;
            text-align: right;
            font-size: 14px;
            text-justify:auto;
           
            
        }
        .im{
           
           height: 40px;
              width: 40px;


        }
        .aa{
            background-position: center;
            text-align: center;
            min-width:40px ;
            text-align: right;
            font-size: 30px;
            text-justify:auto;
           
            
        }
  

}






@media only screen and (min-width: 1200px) {



    .textaliht{


        min-width:40px ;
        text-align: center;
         font-size: 30px;
    
    
    
    
    }
    
    .icon{
    
       
        text-align: center;
        font-size: 15px;
        height: 40px;
        width: 1060px;
        margin-left: 40px;
     
    }
    
    
    
    .img{
     
        text-align: center;
    
        z-index: -1;
       
       
       
    }
    .aa{
        background-position: center;
        text-align: center;
        min-width:40px ;
        text-align: right;
        font-size: 34px;
        text-justify:auto;
       
        
    }
    
    
    .im{
        text-align: center;
        height: 65px;
           width: 65px;


     }



}













  @media only screen and (min-device-width: 200px) and (max-device-width: 300px) {


   
    .icon{

   
        text-align: center;
        font-size: 15px;
        height: 30px;
        width: 1060px;
        margin-left: 70px;
     
    }
    

    .text{


        text-align: right;
        font-size: 10px;
        text-justify:auto;
        font-style: normal;
        }

        .t{

            font-family: David;
            text-align: right;
            font-size: 10px;
            max-width: 768px;
           

        }
        .si{



            height:10px ;
            width: 10px;
        }

    .container{


        max-width: 668px;
    }

    

    
      
    

    
      
}



  @media only screen and (min-device-width: 300px) and (max-device-width: 400px) {


   
    .icon{

   
        text-align: center;
        font-size: 15px;
        height: 30px;
        width: 1060px;
        margin-left: 70px;
     
    }
    

    .text{


        text-align: right;
        font-size: 17px;
        text-justify:auto;
        font-style: normal;
        }

        .t{

            font-family: David;
            text-align: right;
            font-size: 13px;
            max-width: 768px;
           

        }
        .si{



            height:20px ;
            width: 20px;
        }

    .container{


        max-width: 668px;
    }

    

    
      
    

    
      
}

@media only screen and (min-device-width: 400px) and (max-device-width: 550px){
   
    .icon{

   
        text-align: center;
        font-size: 15px;
        height: 30px;
        width: 1060px;
        margin-left: 70px;
     
    }
    

    .text{

        font-family: David;
        text-align: right;
        font-size: 17px;
        text-justify:auto;
        font-style: normal;
        }

        .t{

            font-family: David;
            text-align: right;
            font-size: 15px;
            max-width: 768px;
           

        }
        .si{



            height:22px ;
            width: 22px;
        }

    .container{


        max-width: 668px;
    }

    

    
  

}



@media only screen and (min-device-width: 550px) and (max-device-width: 800px){
    .icon{

   
        text-align: center;
        font-size: 15px;
        height: 30px;
        width: 1060px;
        margin-left: 70px;
     
    }
    

    .text{

        font-family: David;
        text-align: right;
        font-size: 20px;
        text-justify:auto;
        font-style: normal;
        }

        .t{

            font-family: David;
            text-align: right;
            font-size: 20px;
            max-width: 768px;
           

        }
        .si{



            height:25px ;
            width: 25px;
        }

    .container{


        max-width: 668px;
    }


  
  
    
  

}
@media only screen and (min-device-width: 800px) and (max-device-width: 1200px){

    .icon{

   
        text-align: center;
        font-size: 15px;
        height: 30px;
        width: 1060px;
        margin-left: 70px;
     
    }
    

    .text{

        font-family: David;
        text-align: right;
        font-size: 24px;
        text-justify:auto;
        font-style: normal;
        }

        .t{

            font-family: David;
            text-align: right;
            font-size: 24px;
            max-width: 768px;
           

        }
        .si{



            height:27px ;
            width: 27px;
        }

    .container{


        max-width: 668px;
    }


}






@media only screen and (min-width: 1200px) {


    .text{

     
        font-family: David;
        text-align: right;
        font-size: 25px;
        text-justify:auto;
        font-style:normal;
        
        }


        .t{
           
            font-family: David;
            text-align: right;
            font-size: 30px;
            
        }

        

    



}





















  @media only screen and (min-device-width: 300px) and (max-device-width: 400px) {

    .textttttt{
      font-family: David;
        text-align: center;
        font-size: 12px;
     }
    
    
     .abc{
    
    
       text-align: center;
       font-size: 15px;
       height: 40px;
       width: 10px;
       margin-left: 40px;
       margin-top: 350px;
       -webkit-margin-after: auto;
               margin-block-end: auto;
    
    
    }
    

    
.google-maps {  
  width: 80%; 
  height: 40%; 
  margin:  auto;
  position: absolute;
  padding-left: 30px;
  z-index:1;
}

    
      
    

    
      
  }

  @media only screen and (min-device-width: 400px) and (max-device-width: 550px){

  
    .textttttt{
      font-family: David;
        text-align: center;
        font-size: 14px;
     }
    
    
     .abc{
    
    
       text-align: center;
       font-size: 15px;
       height: 40px;
       width: 10px;
       margin-left: 40px;
       margin-top: 370px;
       -webkit-margin-after: auto;
               margin-block-end: auto;
    
    
    }

        
.google-maps {  
  width: 85%; 
  height: 42%; 
  margin:  auto;
  position: absolute;
  padding-left: 25px;
  z-index:1;
}

      
    

  }



  @media only screen and (min-device-width: 550px) and (max-device-width: 800px){

 
    .textttttt{
      font-family: David;
        text-align: center;
        font-size: 18px;
     }
    
    
     .abc{
    
    
      text-align: center;
      font-size: 15px;
      height: 40px;
      width: 10px;
      margin-left: 40px;
      margin-top: 500px;
      -webkit-margin-after: auto;
              margin-block-end: auto;
   
   
   }

       
.google-maps {  
 width: 85%; 
 height: 50%; 
 margin:  auto;
 position: absolute;
 padding-left: 45px;
 z-index:1;
}

    
    
      
    

  }
  @media only screen and (min-device-width: 800px) and (max-device-width: 1200px){


    .textttttt{
      font-family: David;
        text-align: center;
        font-size: 24px;
     }
    
    
     .abc{
    
    
      text-align: center;
      font-size: 15px;
      height: 40px;
      width: 10px;
      margin-left: 40px;
      margin-top: 700px;
      -webkit-margin-after: auto;
              margin-block-end: auto;
   
   
   }

       
.google-maps {  
 width: 65%; 
 height: 70%; 
 margin:  auto;
 position: absolute;
 padding-left: 170px;
 z-index:1;
}


  }





  
  @media only screen and (min-width: 1200px) {
  
  
  
    .textttttt{
      text-align: center;
      font-family: David;
        font-size: 20px;
   
        
     }
    

  
 .m{


  text-align: center;
  margin-left: auto;
  -webkit-margin-after: auto;
          margin-block-end: auto;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  display: flex;
  
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-end;

 }

 .abc{

  text-align: center;
  font-size: 15px;
  height: 40px;
  width: 10px;
  margin-top: 500px;
  -webkit-margin-after: auto;
          margin-block-end: auto;



}

.google-maps {  
  text-align: center;
  width: 50%; 
  height: 50%; 
  margin:  auto;
  position: absolute;
  padding-left: 480px;
  z-index:1;
}

      
  

  
  }
  
  
  

      /*
    Auther: Abdelrhman Said
*/


* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  *:focus,
  *:active {
    outline: none !important;
    -webkit-tap-highlight-color: transparent;
  }
  
  
  
  .wrapper {
    display: inline-flex;
    
  }
  
  .wrapper .icon {
    position: relative;
    background-color: #ffffff;
    border-radius: 50%;
    padding: 15px;
    margin: 10px;
    width: 50px;
    height: 50px;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }
  
  .wrapper .tooltip {
    position: absolute;
    top: 0;
    font-size: 14px;
    background-color: #ffffff;
    color: #ffffff;
    padding: 5px 8px;
    border-radius: 5px;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
    opacity: 0;
    pointer-events: none;
    transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }
  
  .wrapper .tooltip::before {
    position: absolute;
    content: "";
    height: 8px;
    width: 8px;
    background-color: #ffffff;
    bottom: -3px;
    left: 50%;
    -webkit-transform: translate(-50%) rotate(45deg);
            transform: translate(-50%) rotate(45deg);
    transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }
  
  .wrapper .icon:hover .tooltip {
    top: -45px;
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
  }
  
  .wrapper .icon:hover span,
  .wrapper .icon:hover .tooltip {
    text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.1);
  }
  
  .wrapper .facebook:hover,
  .wrapper .facebook:hover .tooltip,
  .wrapper .facebook:hover .tooltip::before {
    background-color: #3b5999;
    color: #ffffff;
  }
  
  .wrapper .twitter:hover,
  .wrapper .twitter:hover .tooltip,
  .wrapper .twitter:hover .tooltip::before {
    background-color: #5cc732;
    color: #ffffff;
  }
  
  .wrapper .instagram:hover,
  .wrapper .instagram:hover .tooltip,
  .wrapper .instagram:hover .tooltip::before {
    background-color: #e1306c;
    color: #ffffff;
  }
  
  .wrapper .github:hover,
  .wrapper .github:hover .tooltip,
  .wrapper .github:hover .tooltip::before {
    background-color: #333333;
    color: #ffffff;
  }
  
  .wrapper .youtube:hover,
  .wrapper .youtube:hover .tooltip,
  .wrapper .youtube:hover .tooltip::before {
    background-color: #de463b;
    color: #ffffff;
  }
  
@media (max-device-width: 350px){

    .wrapper .icon {
      position: relative;
      background-color: #ffffff;
      border-radius: 50%;
      padding: 15px;
      margin: 10px;
      width: 40px;
      height: 40px;
      font-size: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
      cursor: pointer;
      transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    }
  }

  @media (max-device-width: 300px){

    .wrapper .icon {
      position: relative;
      background-color: #ffffff;
      border-radius: 50%;
      padding: 15px;
      margin: 10px;
      width: 10px;
      height: 10px;
      font-size: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
      cursor: pointer;
      transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    }

}








  @media only screen and (min-device-width: 300px) and (max-device-width: 400px) {




    .icon{


        text-align: center;
        font-size: 15px;
        height: 40px;
        width: 1060px;
        margin-left: 8px;
    
    
    }

    .bot{
         

     text-align: center;
     padding-left: 50px;

    }

    .mobile{

        text-align: center;
    
    
    }

    .btnbtn{
        text-align: center;
      margin-right: 140px;
      border-radius: 15px;
    
    }

    .scroll {
        opacity: 0.3;
        background-color: aqua;
        width: 40px;
        height: 40px;
        position: fixed;
        bottom: 10px;
        right: 10px;
        border-radius: 5px;
        border: none;
        
    }



    .btnScrollToTop{




        position: fixed;
        right: 10px;
        bottom: 10px;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background: #ee6622;
        box-shadow: 00 10px red;
        color: #FFFFFF;
        outline: none;
        cursor:pointer;
        
        
        
        }
        
        .bt{
            text-align: center;
            margin-right: 95px;
            border-radius: 15px;
        
        
        }
        
        .teee{

            color: '#bbb';
            font-size: 10px;
            font-weight: bold;
         
            border-radius: 124px;
            text-align: center;
            width: 220px;
            height: 45px;
            margin-right: 13%;
            
          }
 
    

    
      
    

    
      
    }
  
    @media only screen and (min-device-width: 400px) and (max-device-width: 550px){

    
        .icon{


            text-align: center;
            font-size: 15px;
            height: 40px;
            width: 1060px;
            margin-left: 8px;
        
        
        }
    
        .bot{
             
    
         text-align: center;
         padding-left: 50px;
    
        }
    
        .mobile{
    
            text-align: center;
        
        
        }
    
        .btnbtn{
            text-align: center;
          margin-right: 140px;
          border-radius: 15px;
        
        }
    
        .scroll {
            opacity: 0.3;
            background-color: aqua;
            width: 40px;
            height: 40px;
            position: fixed;
            bottom: 10px;
            right: 10px;
            border-radius: 5px;
            border: none;
            
        }
    
    
    
        .btnScrollToTop{
    
    
    
    
            position: fixed;
            right: 10px;
            bottom: 10px;
            width: 50px;
            height: 50px;
            border-radius: 50%;
            background: #ee6622;
            box-shadow: 00 10px red;
            color: #FFFFFF;
            outline: none;
            cursor:pointer;
            
            
            
            }
            
            .bt{
                text-align: center;
                margin-right: 95px;
                border-radius: 15px;
            
            
            }
            
            .teee{
    
                color: '#bbb';
                font-size: 13px;
                font-weight: bold;
              
                border-radius: 124px;
                text-align: center;
                width: 300px;
                height: 45px;
                margin-right: 7%;
                
              }
     
        
      

    }
  


    @media only screen and (min-device-width: 550px) and (max-device-width: 800px){

   
      
        .icon{


            text-align: center;
            font-size: 15px;
            height: 40px;
            width: 1060px;
            margin-left: 8px;
        
        
        }
    
        .bot{
             
    
         text-align: center;
         padding-left: 50px;
    
        }
    
        .mobile{
    
            text-align: center;
        
        
        }
    
        .btnbtn{
            text-align: center;
          margin-right: 140px;
          border-radius: 15px;
        
        }
    
        .scroll {
            opacity: 0.3;
            background-color: aqua;
            width: 40px;
            height: 40px;
            position: fixed;
            bottom: 10px;
            right: 10px;
            border-radius: 5px;
            border: none;
            
        }
    
    
    
        .btnScrollToTop{
    
    
    
    
            position: fixed;
            right: 10px;
            bottom: 10px;
            width: 50px;
            height: 50px;
            border-radius: 50%;
            background: #ee6622;
            box-shadow: 00 10px red;
            color: #FFFFFF;
            outline: none;
            cursor:pointer;
            
            
            
            }
            
            .bt{
                text-align: center;
                margin-right: 95px;
                border-radius: 15px;
            
            
            }
            
            .teee{
    
                color: '#bbb';
                font-size: 13px;
                font-weight: bold;
         
                border-radius: 124px;
                text-align: center;
                width: 500px;
                height: 45px;
                margin-right: 8%;
                
              }
     
      
        
      

    }
    @media only screen and (min-device-width: 800px) and (max-device-width: 1200px){

      
        .icon{


            text-align: center;
            font-size: 15px;
            height: 40px;
            width: 1060px;
            margin-left: 8px;
        
        
        }
    
        .bot{
             
    
         text-align: center;
         padding-left: 50px;
    
        }
    
        .mobile{
    
            text-align: center;
        
        
        }
    
        .btnbtn{
            text-align: center;
          margin-right: 140px;
          border-radius: 15px;
        
        }
    
        .scroll {
            opacity: 0.3;
            background-color: aqua;
            width: 40px;
            height: 40px;
            position: fixed;
            bottom: 10px;
            right: 10px;
            border-radius: 5px;
            border: none;
            
        }
    
    
    
        .btnScrollToTop{
    
    
    
    
            position: fixed;
            right: 10px;
            bottom: 10px;
            width: 50px;
            height: 50px;
            border-radius: 50%;
            background: #ee6622;
            box-shadow: 00 10px red;
            color: #FFFFFF;
            outline: none;
            cursor:pointer;
            
            
            
            }
            
            .bt{
                text-align: center;
                margin-right: 95px;
                border-radius: 15px;
            
            
            }
            
            .teee{
    
                color: '#bbb';
                font-size: 16px;
                font-weight: bold;
     
                border-radius: 124px;
                text-align: center;
                width: 600px;
                height: 49px;
                margin-right: 10%;
                margin-left: 2%;
                
              }

    }


 


    
    @media only screen and (min-width: 1200px) {
    
    
    
          
        .icon{


            text-align: center;
            font-size: 15px;
            height: 40px;
            width: 1060px;
            margin-left: 8px;
        
        
        }
    
        .bot{
             
    
         text-align: center;
         padding-left: 50px;
    
        }
    
        .mobile{
    
            text-align: center;
        
        
        }
    
        .btnbtn{
            text-align: center;
          margin-right: 140px;
          border-radius: 15px;
        
        }
    
        .scroll {
            opacity: 0.3;
            background-color: aqua;
            width: 40px;
            height: 40px;
            position: fixed;
            bottom: 10px;
            right: 10px;
            border-radius: 5px;
            border: none;
            
        }
    
    
    
        .btnScrollToTop{
    
    
    
    
            position: fixed;
            right: 10px;
            bottom: 10px;
            width: 50px;
            height: 50px;
            border-radius: 50%;
            background: #ee6622;
            box-shadow: 00 10px red;
            color: #FFFFFF;
            outline: none;
            cursor:pointer;
            
            
            
            }
            
            .bt{
                text-align: center;
                margin-right: 95px;
                border-radius: 15px;
            
            
            }
            
            .teee{
    
                color: '#bbb';
                font-size: 23px;
                font-weight: bold;
                border-radius: 124px;
                text-align: center;
                width: 800px;
                height: 55px;
                margin-top: auto;
                margin-bottom: auto;
                margin-right: auto;
                margin-left: 13%;
              
              }
              .block_center {
                width: 300px;
                margin-right: auto;
                margin-left: auto;
              }
        
    

    
    }
    
    
    
















  @media only screen and (min-device-width: 300px) and (max-device-width: 400px) {



 
        
        
        .bb{
        
            font-family: David;
            text-align: center;
            font-size: 8px;
            -webkit-font-feature-settings: normal;
                    font-feature-settings: normal;
            font-variant: normal;
          
        
        
        }

    
      
    

    
      
}

@media only screen and (min-device-width: 400px) and (max-device-width: 550px){

     
    .bb{
        
        font-family: David;
        text-align: center;
        font-size: 8px;
        -webkit-font-feature-settings: normal;
                font-feature-settings: normal;
        font-variant: normal;
     
    
    
    }

    
  

}



@media only screen and (min-device-width: 550px) and (max-device-width: 800px){


       
    .bb{
        
        font-family: David;
        text-align: center;
        font-size: 16px;
        -webkit-font-feature-settings: normal;
                font-feature-settings: normal;
        font-variant: normal;
  
    
    
    }

  
    
  

}
@media only screen and (min-device-width: 800px) and (max-device-width: 1200px){

     
    .bb{
        
        font-family: David;
        text-align: center;
        font-size: 17px;
        -webkit-font-feature-settings: normal;
                font-feature-settings: normal;
        font-variant: normal;
     
    
    
    }



}






@media only screen and (min-width: 1200px) {


    .bb{

        font-family: David;
        text-align: center;
        font-size: 20px;
      
       
    }
    

    .body{

        width:100%;
        height: 65vh;
          background-image: url(/static/media/cov.af46b635.jpg);
          background-repeat: no-repeat;
          background-attachment: fixed;
          background-position: center;
          background-size: cover;
          display: flex;
          flex-direction: row;
          justify-content: space-evenly;
          align-items: flex-end;
          padding: 10px;/*OR padding-bottom: 10px;*/
          opacity: 1.0;
       
       }



}










  @media only screen and (min-device-width: 300px) and (max-device-width: 400px) {

   .b{


      text-align: center;
   
      
   }
   
   .immm{
   
      height: 60px;
      width: 75px;
   
   }
   
   
   .ta {
      display: table;
       border-collapse: separate;
       margin: auto;
      margin-left: auto;
      margin-right: auto;
      margin-top: auto;
      margin-bottom: auto;
     }
   

    

    
      
    

    
      
}

@media only screen and (min-device-width: 400px) and (max-device-width: 550px){

   .b{


      text-align: center;
   
      
   }
   
   .immm{
   
      height: 70px;
      width: 85px;
   
   }
   
   
   .ta {
      display: table;
       border-collapse: separate;
       margin: auto;
      margin-left: auto;
      margin-right: auto;
      margin-top: auto;
      margin-bottom: auto;
     }
   
    
  

}



@media only screen and (min-device-width: 550px) and (max-device-width: 800px){


  
   .b{


      text-align: center;
   
      
   }
   
   .immm{
   
      height: 100px;
      width: 125px;
   
   }
   
   
   .ta {
      display: table;
       border-collapse: separate;
       margin: auto;
      margin-left: auto;
      margin-right: auto;
      margin-top: auto;
      margin-bottom: auto;
     }
   
    
  

}
@media only screen and (min-device-width: 800px) and (max-device-width: 1200px){

   .b{


      text-align: center;
   
      
   }
   
   .immm{
   
      height: 120px;
      width: 145px;
   
   }
   
   
   .ta {
      display: table;
       border-collapse: separate;
       margin: auto;
      margin-left: auto;
      margin-right: auto;
      margin-top: auto;
      margin-bottom: auto;
     }
   

}






@media only screen and (min-width: 1200px) {


   .ta {
      display: table;
     
      margin: auto;
     margin-left: auto;
    }
  

  .b{




   text-align: center
   }

   .immm{
    
      transition-duration: 1s;
      height: 140px;
      width: 235px;
      -webkit-transform: scale(1.0);
              transform: scale(1.0);
      transition-property: -webkit-transform;
      transition-property: transform;
      transition-property: transform, -webkit-transform;
   
   
   }

    



}














  @media only screen and (min-device-width: 200px) and (max-device-width: 300px) {


    
    .imgg{
  
      height: 3px;
      width: auto;
      z-index: -1;
      text-align: right;
      background-color:  #0693e3  ;
    
    }
      
      
    .righttt{
  
      text-align: right;
     
   
  
    }
  
    .left{
  
  
      margin-left: 500px;
      text-decoration-color: aquamarine;
      padding-left: 100px;
        }
    
  
        .aaa{
  
          width: 35px;
          height: 35px;
    
        }  

        .accessibility{



          text-align: left;
          margin-left: auto;


        }
      
        .ml-auto{
          font-size: 2rem;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: -30px 0px 18px 16px;
          margin: 0px 0px 0px 0px;
        
        }
      
        .box1{
    
          order: 5;
          
          
           }
          
           .box2{
            
            order: 3;
          
          
            
          }
          .box3{
           
            order: 4;
          
            
          }
          .box4{
          
            
            order: 2;
            
          }
          .box5{
          
          
            order: 1;
            
          }
      
        
      }
    


  @media only screen and (min-device-width: 300px) and (max-device-width: 400px) {


    
    .imgg{
  
      height: 3px;
      width: auto;
      z-index: -1;
      text-align: right;
      background-color:  #0693e3  ;
    
    }
      
      
    .righttt{
  
      text-align: right;
     
   
  
    }
    .left{
  
  
      margin-left: 500px;
      text-decoration-color: aquamarine;
      padding-left: 100px;
        }
    
   
  
        .aaa{
  
          width: 35px;
          height: 35px;
    
        }  
      
        .ml-auto{
          font-size: 2rem;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: -30px 0px 18px 16px;
      
        
        }
        .box1{
    
          order: 5;
          
          
           }
          
           .box2{
            
            order: 3;
          
          
            
          }
          .box3{
           
            order: 4;
          
            
          }
          .box4{
          
            
            order: 2;
            
          }
          .box5{
          
          
            order: 1;
            
          }
      
      
        
      }
    
      @media only screen and (min-device-width: 400px) and (max-device-width: 550px){
  
        
          
    .imgg{
  
      height: 3px;
      width: auto;
      z-index: -1;
      text-align: right;
      background-color:  #0693e3  ;
    
    }
      
    .righttt{
  
      text-align: right;
     
   
  
    }
  
    .left{
  
  
      margin-left: 500px;
      text-decoration-color: aquamarine;
      padding-left: 100px;
        }
    
  
        .aaa{
  
          width: 40px;
          height: 40px;
    
        }  
      
        .ml-auto{
          font-size: 2rem;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: -30px 0px 18px 16px;
          margin: 0px 0px 0px 0px;
        
        }
      
        .box1{
    
          order: 5;
          
          
           }
          
           .box2{
            
            order: 3;
          
          
            
          }
          .box3{
           
            order: 4;
          
            
          }
          .box4{
          
            
            order: 2;
            
          }
          .box5{
          
          
            order: 1;
            
          }
      
      }
    
  
  
      @media only screen and (min-device-width: 550px) and (max-device-width: 800px){
  
        .imgg{
  
          height: 2px;
          width: auto;
          z-index: -1;
          text-align: right;
          background-color:  #0693e3  ;
        
        }
          
        .righttt{
      
          text-align: right;
         
       
      
        }
      
        .left{
      
      
          margin-left: 500px;
          text-decoration-color: aquamarine;
          padding-left: 100px;
            }
        
      
            .aaa{
      
              width: 35px;
              height: 35px;
        
            }  
            .menu-bars {
              margin-left: 2rem;
              font-size: 2rem;
              background: none;
            }
            .nav-menu {
              background-color: #060b26;
              width: 250px;
              height: 100vh;
              display: flex;
              justify-content: center;
              position: fixed;
              top: 0;
              left: -100%;
              transition: 850ms;
            }
            .ml-auto{
              font-size: 2rem;
              display: flex;
              justify-content: center;
              align-items: center;
              padding: -30px 0px 18px 16px;
              margin: 0px 0px 0px 0px;
            
            }
            .box1{
    
              order: 5;
              
              
               }
              
               .box2{
                
                order: 3;
              
              
                
              }
              .box3{
               
                order: 4;
              
                
              }
              .box4{
              
                
                order: 2;
                
              }
              .box5{
              
              
                order: 1;
                
              }
          
            
      
          }
    
        
    
      @media only screen and (min-device-width: 800px) and (max-device-width: 1000px){
  
     
        
        .imgg{
      
          height: 2px;
          width: auto;
          z-index: -1;
          text-align: right;
          background-color:  #0693e3  ;
        
        }
          
        .righttt{
      
          text-align: right;
         
       
      
        }
      
        .left{
      
      
          margin-left: 500px;
          text-decoration-color: aquamarine;
          padding-left: 100px;
            }
        
      
            .aaa{
      
              width: 35px;
              height: 35px;
        
            }  
            .menu-bars {
              margin-left: 2rem;
              font-size: 2rem;
              background: none;
            }
            .nav-menu {
              background-color: #060b26;
              width: 250px;
              height: 100vh;
              display: flex;
              justify-content: center;
              position: fixed;
              top: 0;
              left: -100%;
              transition: 850ms;
            }
            .ml-auto{
         
              display: flex;
              justify-content: center;
              align-items: center;
              padding: -1px 0px 18px 16px;
              margin: 0px 0px 0px 0px;
       
            
            }
            .box1{
    
              order: 5;
              
              
               }
              
               .box2{
                
                order: 3;
              
              
                
              }
              .box3{
               
                order: 4;
              
                
              }
              .box4{
              
                
                order: 2;
                
              }
              .box5{
              
              
                order: 1;
                
              }
          
          
            
      
          }
      @media only screen and (min-device-width: 1000px) and (max-device-width: 1200px){
      
        
    .imgg{
  
      height: 3px;
      width: auto;
      z-index: -1;
      text-align: right;
      background-color:  #0693e3  ;
    
    }
  
    .righttt{
  
  
      text-align: right;
     margin-right: -75px;
  
    }
  
    .left{
  
  
      margin-left: 200px;
      text-decoration-color: aquamarine;
      background-color: black;
  
  
      font-size: 100px;
        }
  
     .aaa{
  
       width: 40px;
       height: 40px;
  
     }
     .container-1{
      display: flex;
   
    
     }
    
     .box1{
    
      order: 5;
      
      
       }
      
       .box2{
        
        order: 3;
      
      
        
      }
      .box3{
       
        order: 4;
      
        
      }
      .box4{
      
        
        order: 2;
        
      }
      .box5{
      
      
        order: 1;
        
      }
  
  
      }
  
  
   
  
  
      
      @media only screen and (min-width: 1200px) {
      
      
  
        .imgg{
  
          height: 3px;
          width: auto;
          z-index: -1;
          text-align: right;
          background-color:  #0693e3  ;
        
        }
      
       
      
        .righttt{
      
          text-align: right;
       
      
        }

        .nav-menu {
          background-color: #060b26;
          width: 250px;
          height: 100vh;
          display: flex;
          justify-content: center;
     
          top: 0;
          left: -100%;
          transition: 850ms;
  

          
        }

  
        .left{
      
      
          margin-left: 500px;
          text-decoration-color: aquamarine;
          padding-left: 100px;
            }
        
      
            .aaa{
      
              width: 50px;
              height: 50px;
        
            }

 .container-1{
  display: flex;

 }

 .box1{

  order: 5;
  
  
   }
  
   .box2{
    
    order: 3;
  
  
    
  }
  .box3{
   
    order: 4;
  
    
  }
  .box4{
  
    
    order: 2;
    
  }
  .box5{
  
  
    order: 1;
    
  }
  
      
      }
      
    
    
    
    
    
      .imgg{
  
        height: 3px;
        width: auto;
        z-index: -1;
        text-align: right;
        background-color:  #0693e3  ;
      
      }

  

  

  @media only screen and (min-device-width: 0px) and (max-device-width: 200px) {

    .imgggggg{
 
      color: '#0c1719';
      font-size: 17px;
      font-weight: bold;
      background-color:  #3a7b83  ;
      border-radius: 25px;
      margin-top: 17px;
      margin-left: 20px
 
    }
    
    .img{
 
      height: 2px;
      width: 2px;
      z-index: -1;
      text-align: center;
      margin-left: auto;
    
    
      
     
  }
  .footer-container {
    background-color: #b8b1b156;
    padding: 1rem 10 2rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-right: auto;
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
    opacity: 0.9;

    
  }
  .dropdown-menu a{
     
    font-family:  monospace;


}
.hai{


  text-align: center;
  font-family: David;
  font-weight: bold;

}
.haim{

width: 10%;
height: 10%;

}
      
  
      
        
      }


  @media only screen and (min-device-width: 200px) and (max-device-width: 300px) {

    .imgggggg{
 
      color: '#0c1719';
      font-size: 17px;
      font-weight: bold;
      background-color:  #3a7b83  ;
      border-radius: 25px;
      margin-top: 17px;
      margin-left: 20px
 
    }
    
    .img{
 
      height: 2px;
      width: 2px;
      z-index: -1;
      text-align: center;
      margin-left: auto;
    
    
      
     
  }
  .footer-container {
    background-color: #b8b1b156;
    padding: 1rem 10 2rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-right: auto;
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
    opacity: 0.9;

    
  }
  .dropdown-menu a{
     
    font-family:  monospace;


}
.hai{


  text-align: center;
  font-family: David;
  font-weight: bold;

}
.haim{

width: 10%;
height: 10%;

}
      
  
      
        
      }
  @media only screen and (min-device-width: 300px) and (max-device-width: 400px) {

    .imgggggg{
     
      color: '#0c1719';
      font-size: 17px;
      font-weight: bold;
      background-color:  #3a7b83  ;
      border-radius: 25px;
      margin-top: 17px;
      margin-left: 20px
 
    }
    
    .img{
 
      
      height:2px;
      width:2px;
      z-index: -1;
      text-align: center;
      margin-left: auto;
    
    
      
     
  }
      
  .footer-container {
    background-color: #b8b1b156;
    padding: 1rem 10 2rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-right: auto;
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
    opacity: 0.9;

    
  }
  .dropdown-menu a{
     
    font-family:  monospace;


}
.hai{


  text-align: center;
  font-family: David;
  font-weight: bold;

}
.haim{

width: 10%;
height: 10%;

}
        
      }
    
      @media only screen and (min-device-width: 400px) and (max-device-width: 550px){
  
        
        .imgggggg{
 
          color: '#0c1719';
          font-size: 17px;
          font-weight: bold;
          background-color:  #3a7b83  ;
          border-radius: 25px;
          margin-top: 17px;
          margin-left: 20px
     
        }
        
        .img{
     
          height: 2px;
          width: 2px;
          z-index: -1;
          text-align: center;
          margin-left: auto;
        
        
          
         
      }
      .footer-container {
       
        background-color: #b8b1b156;
        padding: 1rem 10 2rem 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin-right: auto;
        margin-left: auto;
        margin-top: auto;
        margin-bottom: auto;
        opacity: 0.9;

      }
      .dropdown-menu a{
     
        font-family:  monospace;


    }
    .hai{


      text-align: center;
      font-family: David;
      font-weight: bold;

    }
    .haim{

width: 10%;
height: 10%;

    }
  
      }
    
  
  
      @media only screen and (min-device-width: 550px) and (max-device-width: 800px){
  
        .imgggggg{
 
          color: '#0c1719';
          font-size: 17px;
          font-weight: bold;
          background-color:  #3a7b83  ;
          border-radius: 25px;
          margin-top: 17px;
          margin-left: 20px
     
        }
        
        .img{
     
          height: 2px;
          width: 2px;
          z-index: -1;
          text-align: center;
          margin-left: auto;
        
        
          
         
      }       
      .footer-container {
        background-color: #b8b1b156;
        padding: 1rem 10 2rem 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin-right: auto;
        margin-left: auto;
        margin-top: auto;
        margin-bottom: auto;
        opacity: 0.9;

        
      }
      .dropdown-menu a{
     
        font-family:  monospace;


    }
    .hai{


      text-align: center;
      font-family: David;
      font-weight: bold;

    }
    .haim{

width: 15%;
height: 15%;

    }
          
        
  
      }
      @media only screen and (min-device-width: 800px) and (max-device-width: 1000px){
      
        .imgggggg{
 
          color: '#0c1719';
          font-size: 17px;
          font-weight: bold;
          background-color:  #3a7b83  ;
          border-radius: 25px;
          margin-top: 17px;
          margin-left: 20px
     
        }
        
        .img{
     
          height: 2px;
          width: 2px;
          z-index: -1;
          text-align: center;
          margin-left: auto;
        
        
          
         
      }
      .footer-container {
        background-color: #b8b1b156;
        padding: 1rem 10 2rem 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin-right: auto;
        margin-left: auto;
        margin-top: auto;
        margin-bottom: auto;
        opacity: 0.9;

        
      }
      .dropdown-menu a{
     
        font-family:  monospace;


    }
    .hai{


      text-align: center;
      font-family: David;
      font-weight: bold;

    }
    .haim{

width: 15%;
height: 15%;

    }
  
      }
  
  
      @media only screen and (min-device-width: 1000px) and (max-device-width: 1200px){
      
        .imgggggg{
 
          color: '#0c1719';
          font-size: 17px;
          font-weight: bold;
          background-color:  #3a7b83  ;
          border-radius: 25px;
          margin-top: 17px;
          margin-left: 20px
     
        }
        
        .img{
     
          height: 2px;
          width: 2px;
          z-index: -1;
          text-align: center;
          margin-left: auto;
        
        
          
         
      }
      .footer-container {
        background-color: #ebe3e356;
        padding: 1rem 10 2rem 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin-right: auto;
        margin-left: auto;
        margin-top: auto;
        margin-bottom: auto;
        opacity: 0.9;

   
        
      }
      .dropdown-menu a{
     
        font-family:  monospace;


    }
    .hai{


      text-align: center;
      font-family: David;
      font-weight: bold;

    }
    .haim{

width: 15%;
height: 15%;

    }
  
      }
  
  
  
      
      @media only screen and (min-width: 1200px) {
        

        .imgggggg{
 
          color: '#0c1719';
          font-size: 17px;
          font-weight: bold;
          background-color:  #3a7b83  ;
          border-radius: 25px;
          margin-top: 17px;
          margin-left: 20px
     
        }
   
        .img{
     
        
          width: 20px;
          z-index: -1;
          background-position: center;
          text-align: center;
          margin-right: auto;
          margin-left: auto;
          margin-top: auto;
          margin-bottom: auto;

        
          
         
      }

      .footer-container {
        background-color: #ebe3e356;
        padding: 1rem 10 2rem 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin-right: auto;
        margin-left: auto;
        margin-top: auto;
        margin-bottom: auto;
        opacity: 0.9;
  

   
        
      }

      .custom-dropdown {
        -webkit-appearance: none;
        margin-left: 190px;
        -moz-appearance: none;
        border: 0 !important;
        border-radius: 5px;
        font-size: 14px;
        padding: 10px;
        width: 35%;
        cursor: pointer;
        background-size: 40px 37px;
        color: #000;
      
  
      }



    .hai{


      text-align: center;
      font-family: David;

    }
    .haim{

width: 15%;
height: 15%;

    }
      
      }
      
      
      
      
      
      
      
  
    
  
    

      .footer-container {
        background-color: #ebe3e356;
        padding: 1rem 10 2rem 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin-right: auto;
        margin-left: auto;
        margin-top: auto;
        margin-bottom: auto;
        opacity: 0.9;
  

   
        
      }










  @media only screen and (max-device-width: 100px) {



    body {
      background-color:#FFFFFF;
 
     
  
  
    }
  
    .icon{
  
   
      text-align: center;
      font-size: 15px;
      height: 40px;
      width: 340px;
  
  
    }
  
    .Button {
  
      padding: 10px 10px 10px;
      width:253px ;
         height: auto;
         padding-left: auto;
         opacity: 0.8;
         padding: auto;
         font-size: 39px;
         border-radius:5px;
         left: auto;
         margin-top: 160px;
         margin-bottom: -73px;
         text-align: center;
         z-index: -1;
         float: right;
         -webkit-transform: scaleX(1);
                 transform: scaleX(1);
  
    }
  
    
    .Buttonback{
  
      width:50px ;
      height: 40px;
      
     
  
    }
   
    .texttitle{
  
  
      font-weight: normal;
      color: #FFFFFF;
      font-size: 38px;
      text-align: center;
      
  
  
    }
  
  
  
  .textaliht{
  
  
  
   text-align: right;
   font-size: 17px;
   font-style: normal;
   font-weight: bolder;
  
  
  
  }
  .overlay{
    margin-top: -100%;

    height: 100vh;
    background-image: url(/static/media/dddd.165b4ef8.jpg);
    background-position: top center;
    background-repeat:no-repeat;
    background-size: cover;
    z-index: -1;
    opacity: 1.0;
  }
  
    
  .viv{



    z-index: -1;
    height: 0vh;
    object-fit: cover;
    position: center;
    width: 100%;
    



        }
  
    
      
    

    
      
  }
 

  @media only screen and (min-device-width: 100px) and (max-device-width: 200px) {



    body {
      background-color:#FFFFFF;
 
     
  
  
    }
  
    .icon{
  
   
      text-align: center;
      font-size: 15px;
      height: 40px;
      width: 340px;
  
  
    }
  
    .Button {
  
      padding: 10px 10px 10px;
      width:253px ;
         height: auto;
         padding-left: auto;
         opacity: 0.8;
         padding: auto;
         font-size: 39px;
         border-radius:5px;
         left: auto;
         margin-top: 160px;
         margin-bottom: -73px;
         text-align: center;
         z-index: -1;
         float: right;
         -webkit-transform: scaleX(1);
                 transform: scaleX(1);
  
    }
  
    
    .Buttonback{
  
      width:50px ;
      height: 40px;
      
     
  
    }
   
    .texttitle{
  
  
      font-weight: normal;
      color: #FFFFFF;
      font-size: 38px;
      text-align: center;
      
  
  
    }
  
  
  
  .textaliht{
  
  
  
   text-align: right;
   font-size: 17px;
   font-style: normal;
   font-weight: bolder;
  
  
  
  }
  .overlay{
    margin-top: -55%;

    height: 100vh;
    background-image: url(/static/media/dddd.165b4ef8.jpg);
    background-position: top center;
    background-repeat:no-repeat;
    background-size: cover;
    z-index: -1;
    opacity: 1.0;
  
  }
  
    
  .viv{



    z-index: -1;
    height: 0vh;
    object-fit: cover;
    position: center;
    width: 100%;
    



        }
  
    
      
    

    
      
  }
 

  @media only screen and (min-device-width: 200px) and (max-device-width: 300px) {



    body {
      background-color:#FFFFFF;
  
      
     
  
  
    }
  
    .icon{
  
   
      text-align: center;
      font-size: 15px;
      height: 40px;
      width: 340px;
  
  
    }
  
    .Button {
  
      padding: 10px 10px 10px;
      width:253px ;
         height: auto;
         padding-left: auto;
         opacity: 0.8;
         padding: auto;
         font-size: 39px;
         border-radius:5px;
         left: auto;
         margin-top: 160px;
         margin-bottom: -73px;
         text-align: center;
         z-index: -1;
         float: right;
         -webkit-transform: scaleX(1);
                 transform: scaleX(1);
  
  
    }
  
    
    .Buttonback{
  
      width:50px ;
      height: 40px;
      
     
  
    }
   
    .texttitle{
  
  
      font-weight: normal;
      color: #FFFFFF;
      font-size: 38px;
      text-align: center;
      
  
  
    }
  
  
  
  .textaliht{
  
  
  
   text-align: right;
   font-size: 17px;
   font-style: normal;
   font-weight: bolder;
  
  
  
  }
  .overlay{
    margin-top: -40%;
    height: 100vh;
    background-image: url(/static/media/dddd.165b4ef8.jpg);
    background-position: top center;
    background-repeat:no-repeat;
    background-size: cover;
    z-index: -1;
    opacity: 1.0;
  }
  
    
  .viv{



    z-index: -1;
    height: 0vh;
    object-fit: cover;
    position: center;
    width: 100%;
    



        }
  
    
      
    

    
      
  }
 

  @media only screen and (min-device-width: 300px) and (max-device-width: 400px) {


    body {
      background-color:#FFFFFF;

      
     
  
  
    }
  
  
    .icon{
  
   
      text-align: center;
      font-size: 15px;
      height: 40px;
      width: 340px;
  
  
    }
  
    .Button {
      padding: 10px 10px 10px;
      width:353px ;
         height: 100px;
         padding-left: auto;
         opacity: 0.8;
         padding: auto;
         font-size: 39px;
         border-radius:5px;
         left: auto;
         margin-top: 160px;
         margin-bottom: -73px;
         text-align: center;
         z-index: -1;
         float: right;
         -webkit-transform: scaleX(1);
                 transform: scaleX(1);
         

  
    }
  
    
    .Buttonback{
  
      width:50px ;
      height: 40px;
      
     
  
    }
   
    .texttitle{
  
  
      font-weight: normal;
      color: #FFFFFF;
      font-size: 38px;
      text-align: center;
      
  
  
    }
  
  
  
  .textaliht{
  
  
  
   text-align: right;
   font-size: 17px;
   font-style: normal;
   font-weight: bolder;
  
  
  
  }
  .overlay{
    margin-top: -35%;

    height: 100vh;
    background-image: url(/static/media/dddd.165b4ef8.jpg);
    background-position: top center;
    background-repeat:no-repeat;
    background-size: cover;
    z-index: -1;
    opacity: 1.0;
  
  }
  
    
  
  .viv{



    z-index: -1;
    height: 0vh;
    object-fit: cover;
    position: center;
    width: 100%;
    



        }
  
    
      
    

    
      
  }

  @media only screen and (min-device-width: 400px) and (max-device-width: 500px){

  
    body {
      background-color:#FFFFFF;
      text-align: right;
      font-size: 13px;
      
     
  
  
    }
  
  
    .icon{
  
   
      text-align: center;
      font-size: 15px;
      height: 40px;
      width: 340px;
  
  
    }
  
    .Button {
      
      padding: 20px 10px 10px;
      width:353px ;
         height: 130px;
         padding-left: auto;
         opacity: 0.8;
         padding: auto;
         font-size: 39px;
         border-radius:5px;
         left: auto;
         margin-top: 130px;
         margin-bottom: -53px;
         text-align: center;
         z-index: -1;
         float: right;
         -webkit-transform: scaleX(1);
                 transform: scaleX(1);
         
     
  
  
    }
  
    
    .Buttonback{
  
      width:50px ;
      height: 40px;
      
      
     
  
    }
   
    .texttitle{
  
  
      font-weight: normal;
      color: #FFFFFF;
      font-size: 38px;
      text-align: center;
      
  
  
    }
  
  
  
  .textaliht{
  
  
  
   text-align: right;
   font-size: 17px;
   font-style: normal;
   font-weight: bolder;
  
  
  
  }

  .overlay{

-webkit-margin-before: auto;

        margin-block-start: auto; 
margin-top: -25%;
height: 100vh;
    background-image: url(/static/media/dddd.165b4ef8.jpg);
    background-position: top center;
    background-repeat:no-repeat;
    background-size: cover;
    z-index: -1;
    opacity: 1.0;




   
  
  }
  
  .viv{



    z-index: -1;
    height: 0vh;
    object-fit: cover;
    position: center;
    width: 100%;
    



        }
  
    

  }



  @media only screen and (min-device-width: 500px) and (max-device-width: 600px){

 
    body {
      background-color:#FFFFFF;
 
      
     
  
  
    }
  
  
    .icon{
  
   
      text-align: center;
      font-size: 15px;
      height: 40px;
      width: 340px;
  
  
    }
  
    .Button {
      width:auto ;
      height: 140px;
      padding-left: auto;
      opacity: 0.8;
      padding: auto auto;
      font-size: 30px;
      border-radius: 10px;
      font-weight: bold;
      font-family: Arial, Helvetica, sans-serif;
      flex-direction: row;
      justify-content: center;
      align-items: center;
   
  
    }
  
    
    .Buttonback{
  

      width:70px ;
      height: 50px;


     
  
    }
   
    .texttitle{
  
  
      font-weight: normal;
      color: #FFFFFF;
      font-size: 38px;
      text-align: center;
      
  
  
    }
  
  
  
  .textaliht{
  
  
  
   text-align: right;
   font-size: 17px;
   font-style: normal;
   font-weight: bolder;
  
  
  
  }
  .overlay{
      
    width: 100%;
    height: 100vh;
    src:url(/static/media/55.ef48515f.mp4);
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-end;
    padding: 10px;/*OR padding-bottom: 10px;*/
    opacity: 1.0;
  
  }

  .viv{

    object-fit: cover;
    position:absolute;
    width: 100%;
    height: 100vh;





  }

      
    

  }



  @media only screen and (min-device-width: 600px) and (max-device-width: 700px){
  


  
  
    .icon{
  
   
      text-align: center;
      font-size: 15px;
      height: 40px;
      width: 340px;
  
  
    }
  
    .Button {
  
      width:auto ;
      height: 150px;
      padding-left: 250px;
      opacity: 0.8;
      padding: 15px 32px;
      font-size: 30px;
      border-radius: 0px;
      font-weight: bold;
      font-family: Arial, Helvetica, sans-serif;
      flex-direction: row;
      justify-content: center;
      align-items: center;
  
    }
  
    
    .Buttonback{
  
  
      width:70px ;
      height: 50px;
  
  
     
  
    }
   
    .texttitle{
  
  
      font-weight: normal;
      color: #FFFFFF;
      font-size: 38px;
      text-align: center;
      
  
  
    }
  
  
  
  .textaliht{
  
  
  
   text-align: right;
   font-size: 17px;
   font-style: normal;
   font-weight: bolder;
  
  
  
  }
  .overlay{
      
      
    width: 100%;
    height: 100vh;
    src:url(/static/media/55.ef48515f.mp4);
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-end;
    padding: 10px;/*OR padding-bottom: 10px;*/
    opacity: 1.0;
  
  }
  
  .viv{
  
  
  object-fit: cover;
  position:absolute;
  width: 100%;
  height: 100vh;
  
  
  
  
  }
  }
  
@media only screen and (min-device-width: 700px) and (max-device-width: 800px){
  

  body {
    background-color:#FFFFFF;
 
    
   


  }


  .icon{

 
    text-align: center;
    font-size: 15px;
    height: 40px;
    width: 340px;


  }

  .Button {

    width:auto ;
    height: 150px;
    padding-left: 250px;
    opacity: 0.8;
    padding: 15px 32px;
    font-size: 30px;
    border-radius: 0px;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    flex-direction: row;
    justify-content: center;
    align-items: center;

  }

  
  .Buttonback{


    width:70px ;
    height: 50px;


   

  }
 
  .texttitle{


    font-weight: normal;
    color: #FFFFFF;
    font-size: 38px;
    text-align: center;
    


  }



.textaliht{



 text-align: right;
 font-size: 17px;
 font-style: normal;
 font-weight: bolder;



}
.overlay{
    
    
  width: 100%;
  height: 100vh;
  src:url(/static/media/55.ef48515f.mp4);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-end;
  padding: 10px;/*OR padding-bottom: 10px;*/
  opacity: 1.0;

}

.viv{


object-fit: cover;
position:absolute;
width: 100%;
height: 100vh;




}
}

    
@media only screen and (min-device-width: 800px) and (max-device-width: 900px){
  

  body {
    background-color:#FFFFFF;

   


  }


  .icon{

 
    text-align: center;
    font-size: 15px;
    height: 40px;
    width: 340px;


  }

  .Button {

    width:auto ;
    height: 200px;
    padding-left: 250px;
    opacity: 0.8;
    padding: 15px 32px;
    font-size: 30px;
    border-radius: 0px;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

  }

  
  .Buttonback{


    width:70px ;
    height: 50px;


   

  }
 
  .texttitle{


    font-weight: normal;
    color: #FFFFFF;
    font-size: 38px;
    text-align: center;
    


  }



.textaliht{



 text-align: right;
 font-size: 17px;
 font-style: normal;
 font-weight: bolder;



}
.overlay{
    
    
  width: 100%;
  height: 100vh;
  src:url(/static/media/55.ef48515f.mp4);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-end;
  padding: 10px;/*OR padding-bottom: 10px;*/
  opacity: 1.0;

}

.viv{


object-fit: cover;
position:absolute;
width: 100%;
height: 100vh;




}
}

  @media only screen and (min-device-width: 900px) {

  
    .overlay{
 
    width: 100%;
      height: 100vh;
      src:url(/static/media/55.ef48515f.mp4);
      background-repeat: no-repeat;
      background-attachment: fixed;
      background-position: center;
      background-size: cover;
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      align-items: flex-end;
      padding: 30px;/*OR padding-bottom: 10px;*/
      opacity: 1.0;
    
    }

    .viv{


object-fit: cover;
position:absolute;
width: 100%;
height: 100vh;
  


    }
    
    

  
  .Button {
    width:460px ;
    height: 190px;
    padding-left: 250px;
    opacity: 0.8;
    padding: 15px 32px;
    font-size: 40px;
    border-radius: 0px;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    
  
  
  }
  
  
  
  .texttitle{
  
  
    font-weight: normal;
    color: #FFFFFF;
    font-size: 60px;
    text-align: center;
    
  
  
  }
  
  .Buttonback{
  
    width:70px ;
    height: 60px;
    
   
  
  }
  
  
  .it{
  
  
     
    width:400px ;
    height: 200px;
    padding-left: 250px;
    opacity: 0.8;
    padding: 15px 32px;
    font-size: 40px;
    border-radius: 0px;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
  }
  
  
      
    

    
      
  }


  















 
