



  @media only screen and (min-device-width: 200px) and (max-device-width: 300px) {


   
    .icon{

   
        text-align: center;
        font-size: 15px;
        height: 30px;
        width: 1060px;
        margin-left: 70px;
     
    }
    

    .text{


        text-align: right;
        font-size: 10px;
        text-justify:auto;
        font-style: normal;
        }

        .t{

            font-family: David;
            text-align: right;
            font-size: 10px;
            max-width: 768px;
           

        }
        .si{



            height:10px ;
            width: 10px;
        }

    .container{


        max-width: 668px;
    }

    

    
      
    

    
      
}



  @media only screen and (min-device-width: 300px) and (max-device-width: 400px) {


   
    .icon{

   
        text-align: center;
        font-size: 15px;
        height: 30px;
        width: 1060px;
        margin-left: 70px;
     
    }
    

    .text{


        text-align: right;
        font-size: 17px;
        text-justify:auto;
        font-style: normal;
        }

        .t{

            font-family: David;
            text-align: right;
            font-size: 13px;
            max-width: 768px;
           

        }
        .si{



            height:20px ;
            width: 20px;
        }

    .container{


        max-width: 668px;
    }

    

    
      
    

    
      
}

@media only screen and (min-device-width: 400px) and (max-device-width: 550px){
   
    .icon{

   
        text-align: center;
        font-size: 15px;
        height: 30px;
        width: 1060px;
        margin-left: 70px;
     
    }
    

    .text{

        font-family: David;
        text-align: right;
        font-size: 17px;
        text-justify:auto;
        font-style: normal;
        }

        .t{

            font-family: David;
            text-align: right;
            font-size: 15px;
            max-width: 768px;
           

        }
        .si{



            height:22px ;
            width: 22px;
        }

    .container{


        max-width: 668px;
    }

    

    
  

}



@media only screen and (min-device-width: 550px) and (max-device-width: 800px){
    .icon{

   
        text-align: center;
        font-size: 15px;
        height: 30px;
        width: 1060px;
        margin-left: 70px;
     
    }
    

    .text{

        font-family: David;
        text-align: right;
        font-size: 20px;
        text-justify:auto;
        font-style: normal;
        }

        .t{

            font-family: David;
            text-align: right;
            font-size: 20px;
            max-width: 768px;
           

        }
        .si{



            height:25px ;
            width: 25px;
        }

    .container{


        max-width: 668px;
    }


  
  
    
  

}
@media only screen and (min-device-width: 800px) and (max-device-width: 1200px){

    .icon{

   
        text-align: center;
        font-size: 15px;
        height: 30px;
        width: 1060px;
        margin-left: 70px;
     
    }
    

    .text{

        font-family: David;
        text-align: right;
        font-size: 24px;
        text-justify:auto;
        font-style: normal;
        }

        .t{

            font-family: David;
            text-align: right;
            font-size: 24px;
            max-width: 768px;
           

        }
        .si{



            height:27px ;
            width: 27px;
        }

    .container{


        max-width: 668px;
    }


}






@media only screen and (min-width: 1200px) {


    .text{

     
        font-family: David;
        text-align: right;
        font-size: 25px;
        text-justify:auto;
        font-style:normal;
        
        }


        .t{
           
            font-family: David;
            text-align: right;
            font-size: 30px;
            
        }

        

    



}

















