
  @media only screen and (max-device-width: 100px) {



    body {
      background-color:#FFFFFF;
 
     
  
  
    }
  
    .icon{
  
   
      text-align: center;
      font-size: 15px;
      height: 40px;
      width: 340px;
  
  
    }
  
    .Button {
  
      padding: 10px 10px 10px;
      width:253px ;
         height: auto;
         padding-left: auto;
         opacity: 0.8;
         padding: auto;
         font-size: 39px;
         border-radius:5px;
         left: auto;
         margin-top: 160px;
         margin-bottom: -73px;
         text-align: center;
         z-index: -1;
         float: right;
         transform: scaleX(1);
  
    }
  
    
    .Buttonback{
  
      width:50px ;
      height: 40px;
      
     
  
    }
   
    .texttitle{
  
  
      font-weight: normal;
      color: #FFFFFF;
      font-size: 38px;
      text-align: center;
      
  
  
    }
  
  
  
  .textaliht{
  
  
  
   text-align: right;
   font-size: 17px;
   font-style: normal;
   font-weight: bolder;
  
  
  
  }
  .overlay{
    margin-top: -100%;

    height: 100vh;
    background-image: url('../assets/dddd.jpg')  ;
    background-position: top center;
    background-repeat:no-repeat;
    background-size: cover;
    z-index: -1;
    opacity: 1.0;
  }
  
    
  .viv{



    z-index: -1;
    height: 0vh;
    object-fit: cover;
    position: center;
    width: 100%;
    



        }
  
    
      
    

    
      
  }
 

  @media only screen and (min-device-width: 100px) and (max-device-width: 200px) {



    body {
      background-color:#FFFFFF;
 
     
  
  
    }
  
    .icon{
  
   
      text-align: center;
      font-size: 15px;
      height: 40px;
      width: 340px;
  
  
    }
  
    .Button {
  
      padding: 10px 10px 10px;
      width:253px ;
         height: auto;
         padding-left: auto;
         opacity: 0.8;
         padding: auto;
         font-size: 39px;
         border-radius:5px;
         left: auto;
         margin-top: 160px;
         margin-bottom: -73px;
         text-align: center;
         z-index: -1;
         float: right;
         transform: scaleX(1);
  
    }
  
    
    .Buttonback{
  
      width:50px ;
      height: 40px;
      
     
  
    }
   
    .texttitle{
  
  
      font-weight: normal;
      color: #FFFFFF;
      font-size: 38px;
      text-align: center;
      
  
  
    }
  
  
  
  .textaliht{
  
  
  
   text-align: right;
   font-size: 17px;
   font-style: normal;
   font-weight: bolder;
  
  
  
  }
  .overlay{
    margin-top: -55%;

    height: 100vh;
    background-image: url('../assets/dddd.jpg')  ;
    background-position: top center;
    background-repeat:no-repeat;
    background-size: cover;
    z-index: -1;
    opacity: 1.0;
  
  }
  
    
  .viv{



    z-index: -1;
    height: 0vh;
    object-fit: cover;
    position: center;
    width: 100%;
    



        }
  
    
      
    

    
      
  }
 

  @media only screen and (min-device-width: 200px) and (max-device-width: 300px) {



    body {
      background-color:#FFFFFF;
  
      
     
  
  
    }
  
    .icon{
  
   
      text-align: center;
      font-size: 15px;
      height: 40px;
      width: 340px;
  
  
    }
  
    .Button {
  
      padding: 10px 10px 10px;
      width:253px ;
         height: auto;
         padding-left: auto;
         opacity: 0.8;
         padding: auto;
         font-size: 39px;
         border-radius:5px;
         left: auto;
         margin-top: 160px;
         margin-bottom: -73px;
         text-align: center;
         z-index: -1;
         float: right;
         transform: scaleX(1);
  
  
    }
  
    
    .Buttonback{
  
      width:50px ;
      height: 40px;
      
     
  
    }
   
    .texttitle{
  
  
      font-weight: normal;
      color: #FFFFFF;
      font-size: 38px;
      text-align: center;
      
  
  
    }
  
  
  
  .textaliht{
  
  
  
   text-align: right;
   font-size: 17px;
   font-style: normal;
   font-weight: bolder;
  
  
  
  }
  .overlay{
    margin-top: -40%;
    height: 100vh;
    background-image: url('../assets/dddd.jpg')  ;
    background-position: top center;
    background-repeat:no-repeat;
    background-size: cover;
    z-index: -1;
    opacity: 1.0;
  }
  
    
  .viv{



    z-index: -1;
    height: 0vh;
    object-fit: cover;
    position: center;
    width: 100%;
    



        }
  
    
      
    

    
      
  }
 

  @media only screen and (min-device-width: 300px) and (max-device-width: 400px) {


    body {
      background-color:#FFFFFF;

      
     
  
  
    }
  
  
    .icon{
  
   
      text-align: center;
      font-size: 15px;
      height: 40px;
      width: 340px;
  
  
    }
  
    .Button {
      padding: 10px 10px 10px;
      width:353px ;
         height: 100px;
         padding-left: auto;
         opacity: 0.8;
         padding: auto;
         font-size: 39px;
         border-radius:5px;
         left: auto;
         margin-top: 160px;
         margin-bottom: -73px;
         text-align: center;
         z-index: -1;
         float: right;
         transform: scaleX(1);
         

  
    }
  
    
    .Buttonback{
  
      width:50px ;
      height: 40px;
      
     
  
    }
   
    .texttitle{
  
  
      font-weight: normal;
      color: #FFFFFF;
      font-size: 38px;
      text-align: center;
      
  
  
    }
  
  
  
  .textaliht{
  
  
  
   text-align: right;
   font-size: 17px;
   font-style: normal;
   font-weight: bolder;
  
  
  
  }
  .overlay{
    margin-top: -35%;

    height: 100vh;
    background-image: url('../assets/dddd.jpg')  ;
    background-position: top center;
    background-repeat:no-repeat;
    background-size: cover;
    z-index: -1;
    opacity: 1.0;
  
  }
  
    
  
  .viv{



    z-index: -1;
    height: 0vh;
    object-fit: cover;
    position: center;
    width: 100%;
    



        }
  
    
      
    

    
      
  }

  @media only screen and (min-device-width: 400px) and (max-device-width: 500px){

  
    body {
      background-color:#FFFFFF;
      text-align: right;
      font-size: 13px;
      
     
  
  
    }
  
  
    .icon{
  
   
      text-align: center;
      font-size: 15px;
      height: 40px;
      width: 340px;
  
  
    }
  
    .Button {
      
      padding: 20px 10px 10px;
      width:353px ;
         height: 130px;
         padding-left: auto;
         opacity: 0.8;
         padding: auto;
         font-size: 39px;
         border-radius:5px;
         left: auto;
         margin-top: 130px;
         margin-bottom: -53px;
         text-align: center;
         z-index: -1;
         float: right;
         transform: scaleX(1);
         
     
  
  
    }
  
    
    .Buttonback{
  
      width:50px ;
      height: 40px;
      
      
     
  
    }
   
    .texttitle{
  
  
      font-weight: normal;
      color: #FFFFFF;
      font-size: 38px;
      text-align: center;
      
  
  
    }
  
  
  
  .textaliht{
  
  
  
   text-align: right;
   font-size: 17px;
   font-style: normal;
   font-weight: bolder;
  
  
  
  }

  .overlay{

margin-block-start: auto; 
margin-top: -25%;
height: 100vh;
    background-image: url('../assets/dddd.jpg')  ;
    background-position: top center;
    background-repeat:no-repeat;
    background-size: cover;
    z-index: -1;
    opacity: 1.0;




   
  
  }
  
  .viv{



    z-index: -1;
    height: 0vh;
    object-fit: cover;
    position: center;
    width: 100%;
    



        }
  
    

  }



  @media only screen and (min-device-width: 500px) and (max-device-width: 600px){

 
    body {
      background-color:#FFFFFF;
 
      
     
  
  
    }
  
  
    .icon{
  
   
      text-align: center;
      font-size: 15px;
      height: 40px;
      width: 340px;
  
  
    }
  
    .Button {
      width:auto ;
      height: 140px;
      padding-left: auto;
      opacity: 0.8;
      padding: auto auto;
      font-size: 30px;
      border-radius: 10px;
      font-weight: bold;
      font-family: Arial, Helvetica, sans-serif;
      flex-direction: row;
      justify-content: center;
      align-items: center;
   
  
    }
  
    
    .Buttonback{
  

      width:70px ;
      height: 50px;


     
  
    }
   
    .texttitle{
  
  
      font-weight: normal;
      color: #FFFFFF;
      font-size: 38px;
      text-align: center;
      
  
  
    }
  
  
  
  .textaliht{
  
  
  
   text-align: right;
   font-size: 17px;
   font-style: normal;
   font-weight: bolder;
  
  
  
  }
  .overlay{
      
    width: 100%;
    height: 100vh;
    src:url('./video/55.mp4');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-end;
    padding: 10px;/*OR padding-bottom: 10px;*/
    opacity: 1.0;
  
  }

  .viv{

    object-fit: cover;
    position:absolute;
    width: 100%;
    height: 100vh;





  }

      
    

  }



  @media only screen and (min-device-width: 600px) and (max-device-width: 700px){
  


  
  
    .icon{
  
   
      text-align: center;
      font-size: 15px;
      height: 40px;
      width: 340px;
  
  
    }
  
    .Button {
  
      width:auto ;
      height: 150px;
      padding-left: 250px;
      opacity: 0.8;
      padding: 15px 32px;
      font-size: 30px;
      border-radius: 0px;
      font-weight: bold;
      font-family: Arial, Helvetica, sans-serif;
      flex-direction: row;
      justify-content: center;
      align-items: center;
  
    }
  
    
    .Buttonback{
  
  
      width:70px ;
      height: 50px;
  
  
     
  
    }
   
    .texttitle{
  
  
      font-weight: normal;
      color: #FFFFFF;
      font-size: 38px;
      text-align: center;
      
  
  
    }
  
  
  
  .textaliht{
  
  
  
   text-align: right;
   font-size: 17px;
   font-style: normal;
   font-weight: bolder;
  
  
  
  }
  .overlay{
      
      
    width: 100%;
    height: 100vh;
    src:url('./video/55.mp4');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-end;
    padding: 10px;/*OR padding-bottom: 10px;*/
    opacity: 1.0;
  
  }
  
  .viv{
  
  
  object-fit: cover;
  position:absolute;
  width: 100%;
  height: 100vh;
  
  
  
  
  }
  }
  
@media only screen and (min-device-width: 700px) and (max-device-width: 800px){
  

  body {
    background-color:#FFFFFF;
 
    
   


  }


  .icon{

 
    text-align: center;
    font-size: 15px;
    height: 40px;
    width: 340px;


  }

  .Button {

    width:auto ;
    height: 150px;
    padding-left: 250px;
    opacity: 0.8;
    padding: 15px 32px;
    font-size: 30px;
    border-radius: 0px;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    flex-direction: row;
    justify-content: center;
    align-items: center;

  }

  
  .Buttonback{


    width:70px ;
    height: 50px;


   

  }
 
  .texttitle{


    font-weight: normal;
    color: #FFFFFF;
    font-size: 38px;
    text-align: center;
    


  }



.textaliht{



 text-align: right;
 font-size: 17px;
 font-style: normal;
 font-weight: bolder;



}
.overlay{
    
    
  width: 100%;
  height: 100vh;
  src:url('./video/55.mp4');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-end;
  padding: 10px;/*OR padding-bottom: 10px;*/
  opacity: 1.0;

}

.viv{


object-fit: cover;
position:absolute;
width: 100%;
height: 100vh;




}
}

    
@media only screen and (min-device-width: 800px) and (max-device-width: 900px){
  

  body {
    background-color:#FFFFFF;

   


  }


  .icon{

 
    text-align: center;
    font-size: 15px;
    height: 40px;
    width: 340px;


  }

  .Button {

    width:auto ;
    height: 200px;
    padding-left: 250px;
    opacity: 0.8;
    padding: 15px 32px;
    font-size: 30px;
    border-radius: 0px;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

  }

  
  .Buttonback{


    width:70px ;
    height: 50px;


   

  }
 
  .texttitle{


    font-weight: normal;
    color: #FFFFFF;
    font-size: 38px;
    text-align: center;
    


  }



.textaliht{



 text-align: right;
 font-size: 17px;
 font-style: normal;
 font-weight: bolder;



}
.overlay{
    
    
  width: 100%;
  height: 100vh;
  src:url('./video/55.mp4');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-end;
  padding: 10px;/*OR padding-bottom: 10px;*/
  opacity: 1.0;

}

.viv{


object-fit: cover;
position:absolute;
width: 100%;
height: 100vh;




}
}

  @media only screen and (min-device-width: 900px) {

  
    .overlay{
 
    width: 100%;
      height: 100vh;
      src:url('./video/55.mp4');
      background-repeat: no-repeat;
      background-attachment: fixed;
      background-position: center;
      background-size: cover;
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      align-items: flex-end;
      padding: 30px;/*OR padding-bottom: 10px;*/
      opacity: 1.0;
    
    }

    .viv{


object-fit: cover;
position:absolute;
width: 100%;
height: 100vh;
  


    }
    
    

  
  .Button {
    width:460px ;
    height: 190px;
    padding-left: 250px;
    opacity: 0.8;
    padding: 15px 32px;
    font-size: 40px;
    border-radius: 0px;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    
  
  
  }
  
  
  
  .texttitle{
  
  
    font-weight: normal;
    color: #FFFFFF;
    font-size: 60px;
    text-align: center;
    
  
  
  }
  
  .Buttonback{
  
    width:70px ;
    height: 60px;
    
   
  
  }
  
  
  .it{
  
  
     
    width:400px ;
    height: 200px;
    padding-left: 250px;
    opacity: 0.8;
    padding: 15px 32px;
    font-size: 40px;
    border-radius: 0px;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
  }
  
  
      
    

    
      
  }


  















 